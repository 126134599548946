export default function tableResultsResponseToTableRows(results) {
    if (!results) return null;

    return results.map(({fields}) => ({
        ...fields.reduce((acc, {key, value}) => {
            acc[key] = value;
            return acc;
        }, {})
    }));
}
