import React, {useState} from 'react';
import {useParams} from "react-router-dom";
import ModuleStatisticsListing
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsListing/ModuleStatisticsListing";
import ModuleStatisticsForm from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/ModuleStatisticsForm";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import ErrorBoundary from "components/Error/errorBoundary";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import "./ModuleStatisticsPage.scss";

export default function ModuleStatisticsPage() {
    const {t} = usePolTranslation();
    const {moduleId} = useParams();
    const [cardHeaderTools, setCardHeaderTools] = useState(null);

    if (moduleId) return <ModuleStatisticsForm moduleId={moduleId}/> // moduleId can be 'new' which is handled by the component

    return (
        <AdminCard title={t('manage_statistics')}
                   CardHeaderTools={() => cardHeaderTools}>
            <ErrorBoundary canRetry>
                <ModuleStatisticsListing setCardHeaderTools={setCardHeaderTools}/>
            </ErrorBoundary>
        </AdminCard>
    )
}
