import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import PolButton from "v4/components/ui/PolButton/PolButton";

export default function SubmitButton({isLoading, formId, isDisabled, children, ...buttonProps}) {
    return (
        <PolButton type="submit"
                   isDisabled={isLoading ?? isDisabled}
                   className="btn-primary btn-submit"
                   {...(formId ? {form: formId} : {})}
                   {...buttonProps}>
            {children}
            {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
        </PolButton>
    )
}
