import React from 'react';
import useModuleStatisticsForm
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/hooks/useModuleStatisticsForm";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import SubmitButton from "v4/components/ui/SubmitButton/SubmitButton";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {routesBase} from "v4/data/appRoutes";
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import AdminCard from "v4/components/ui/AdminCard/AdminCard";
import ModuleStatisticsRenderBlockForm
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/components/ModuleStatisticsRenderBlockForm/ModuleStatisticsRenderBlockForm";
import ModuleStatisticsRenderTableForm
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/components/ModuleStatisticsRenderTableForm/ModuleStatisticsRenderTableForm";
import FormRender from "v4/components/form/FormRender/FormRender";
import ModuleStatisticsRenderProductForm
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/components/ModuleStatisticsRenderProductForm/ModuleStatisticsRenderProductForm";
import {
    STATISTIC_MODULE_BLOCK_TYPE,
    STATISTIC_MODULE_PRODUCT_TYPE,
    STATISTIC_MODULE_TABLE_TYPE,
    STATISTIC_MODULE_TURNOVER_TYPE
} from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsTools/ModuleStatisticsTools";
import ModuleStatisticsRenderTurnoverForm
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsForm/components/ModuleStatisticsRenderTurnoverForm/ModuleStatisticsRenderTurnoverForm";

export default function ModuleStatisticsForm({moduleId}) {
    const {t} = usePolTranslation();
    const {
        formId,
        formType,
        form,
        isLoading,
        onSubmit,
        isSaving,
    } = useModuleStatisticsForm(moduleId);


    return (
        <AdminCard title={formType ? t(`statistic:new_${formType}`) : t('loading')}
                   CardHeaderTools={() => (
                       <>
                           <PolButton to={routesBase.adminModuleStatistics}>
                               <PolIcon icon="return"/>
                               {t('return')}
                           </PolButton>

                           <SubmitButton formId={formId} isDisabled={isLoading} isLoading={isSaving}>
                               {t('enregistrer')}
                           </SubmitButton>
                       </>
                   )}>
            {isLoading
                ? <InlineLoader/>
                : renderForm({formType, formId, form, onSubmit})
            }
        </AdminCard>
    )
}

function renderForm({formType, formId, form, onSubmit}) {
    switch (formType) {
        case STATISTIC_MODULE_BLOCK_TYPE:
            return <ModuleStatisticsRenderBlockForm formId={formId} form={form} onSubmit={onSubmit}/>;
        case STATISTIC_MODULE_TABLE_TYPE:
            return <ModuleStatisticsRenderTableForm formId={formId} form={form} onSubmit={onSubmit}/>;
        case STATISTIC_MODULE_PRODUCT_TYPE:
            return <ModuleStatisticsRenderProductForm formId={formId} form={form} onSubmit={onSubmit}/>;
        case STATISTIC_MODULE_TURNOVER_TYPE:
            return <ModuleStatisticsRenderTurnoverForm formId={formId} form={form} onSubmit={onSubmit}/>;
        default:
            return <FormRender formId={formId} form={form} onSubmit={onSubmit}/>;
    }
}
