import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import DownloadLink from "v4/components/utils/DownloadLink/DownloadLink";
import {IMAGE, LIVEO_CALLS} from "v4/data/apiRoutes";
import QuotesProductsDetails from "v4/features/front/products/components/QuotesProductsDetails/QuotesProductsDetails";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {generateUrl} from "v4/services/api.service";
import {getFormattedValue} from "v4/utils";
import DocumentsList from "v4/components/form/ShowField/components/DocumentsList/DocumentsList";
import TextareaRenderer from "v4/components/form/ShowField/components/TextareaRenderer/TextareaRenderer";
import {QUOTE_LINE_TYPE} from "v4/data/fieldTypes";

export default function ShowField({
                                      type,
                                      value,
                                      label,
                                      choices,
                                      attr,
                                      fullname,
                                      properties,
                                      entry_type,
                                      specificParams,
                                      allInputs,
                                  }) {
    const {t} = usePolTranslation();
    const {addSuccessNotification, addErrorNotification} = useNotification();

    const [{isError}, fetchLiveo] = useFetch();

    const handleLiveoCall =
        fullname && value
            ? () => {
                fetchLiveo({
                    url: generateUrl(LIVEO_CALLS),
                    config: {
                        method: 'POST',
                        body: JSON.stringify({
                            contactName: fullname,
                            number: value,
                        })
                    }
                })
                addSuccessNotification(t('calling'));
            }
            : null;

    useEffect(() => {
        if (isError) {
            addErrorNotification(t('error_liveo'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError])

    const typeToSwitch = attr?.type ?? type;

    if (false === attr?.['data-condition-is-visible']) {
        return null;
    }

    switch (typeToSwitch) {
        case 'text':
        case 'siret':
        case 'TextType':
            // If the value is a URL, we display it as a link
            try {
                new URL(value); // If the value is not a URL, it will throw an error, no need to get the result
                return (
                    <p className={`show-field ${typeToSwitch.toLowerCase()} url`}>
                        {label && <span className="show-field__label">{t(label)} : </span>}
                        <span className="show-field__value">
                            <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
                        </span>
                    </p>
                );
            } catch (e) {
                // If the value is not a URL, continue
            }

            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        {getFormattedValue(typeToSwitch, value, {polTraduction: t})}
                    </span>
                </p>
            );


        case 'number':
        case 'NumberType':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        {isNaN(parseFloat(value)) ? '' : getSymbolIfNeeded(attr, t(parseFloat(value)))}
                    </span>
                </p>
            )


        case 'checkbox':
        case 'CheckboxType':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        {toBoolean(value) ? t('Oui') : t('Non')}
                    </span>
                </p>
            )

        case 'datetime':
        case 'DateTimeType':
        case 'date':
        case 'DateType':
            return <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                {label && <span className="show-field__label">{t(label)} : </span>}
                <span className="show-field__value">
                    {getFormattedValue(typeToSwitch, value, {keepTime: true})}
                </span>
            </p>

        case 'TelType':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        <span className="liveo-format" onClick={handleLiveoCall}>
                            {handleLiveoCall && <PolIcon icon="phone"/>}
                            {value}
                        </span>
                    </span>
                </p>
            )

        case 'email':
        case 'EmailType':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        <a href={`mailto:${value?.replace(/ /g, '').trim()}`}>
                            {value}
                        </a>
                    </span>
                </p>
            )

        case 'file':
        case 'FileType':
        case 'CustomerFileType':
            if (!value) return null;

            return (
                <div className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        {value.mimeType?.startsWith('image/')
                            ? <img src={generateUrl(IMAGE, {id: value.id})}
                                   alt={value.fileLabel}/>
                            : (
                                <DownloadLink href={value?.downloadLink} fileName={value?.fileLabel}>
                                    <PolIcon icon="file" className="file-icon"/>
                                    <span>{value.fileLabel}</span>
                                </DownloadLink>
                            )
                        }
                    </span>
                </div>
            )

        case 'CollectionType':
            if (entry_type === 'CustomerFileType') {
                return (
                    <div className={`show-field ${typeToSwitch.toLowerCase()}`}>
                        {label && <span className="show-field__label">{t(label)} : </span>}
                        <DocumentsList filesFromServer={value ?? []} whenDeleteFile={specificParams?.whenDeleteFile}/>
                    </div>
                )
            } else if (entry_type === QUOTE_LINE_TYPE) {
                return <QuotesProductsDetails key={JSON.stringify(value)} isShow={true} defaultValues={value}
                                              properties={properties}/>
            }

            return <p className="input-field__error">L'input de type {type ?? '"erreur"'} n'est pas géré</p>;

        case 'select':
        case 'ChoiceType':
            return <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                {label && <span className="show-field__label">{t(label)} : </span>}
                <span className="show-field__value">
                    {t(getValuesFromChoices(choices, value))}
                </span>
            </p>

        case 'textarea':
        case 'TextareaType':
        case 'text-rich-editor':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <TextareaRenderer value={value} className="show-field__value" allInputs={allInputs}/>
                </p>
            );

        case 'slider':
        case 'range':
            return (
                <p className={`show-field ${typeToSwitch.toLowerCase()}`}>
                    {label && <span className="show-field__label">{t(label)} : </span>}
                    <span className="show-field__value">
                        {value}%
                    </span>
                </p>
            );

        case 'hidden':
        case 'HiddenType':
            return null;
        default:
            return <p className="show-field__error">L'input de type {typeToSwitch ?? '"erreur"'} n'est pas géré</p>
    }
}

function getValuesFromChoices(choices, value) {
    if (Array.isArray(value)) {
        return value.map(v => getValuesFromChoices(choices, v)).join(', ');
    }

    return choices.find(({value: choiceValue}) => choiceValue === value)?.label ?? value;
}

function getSymbolIfNeeded(attr, value) {
    switch (attr?.['data-sub-type']) {
        case 'money':
            return Intl.NumberFormat('fr-FR', {style: 'currency', currency: 'EUR'})?.format(value);
        case 'percent':
            return value + '%';
        default:
            return value;
    }
}

function toBoolean(value) {
    if (typeof value === 'boolean') return value;
    if (typeof value === 'string') return value.toLowerCase() === 'true';

    return false;
}
