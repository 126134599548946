import React from 'react';
import {routesBase} from "v4/data/appRoutes";
import {TAB_STATS_360} from "v4/data/tabNames";
import {PolIcon} from "components/PolIcon/policon";
import {Link} from "react-router-dom";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import useProspectStats360TabProductViewActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/components/ProspectStats360TabProductViewActions/hooks/useProspectStats360TabProductViewActions";
import {FormProvider} from "react-hook-form";
import FieldWrapper from "v4/components/form/FieldWrapper/FieldWrapper";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/components/ProspectStats360TabProductViewActions/ProspectStats360TabProductViewActions.scss';

export default function ProspectStats360TabProductViewActions({methods, productsModules}) {
    const {t} = usePolTranslation();
    const {prospectId} = useProspectPageContext();
    const {fields} = useProspectStats360TabProductViewActions(productsModules);

    return (
        <div className="prospect-tab__actions__360-view">
            <FormProvider {...methods}>
                <form className="prospect-tab__actions__360-view__form">
                    <h3>{t('order_by')}:</h3>
                    {fields.map(field => (
                        <FieldWrapper key={field.name} {...field}/>
                    ))}
                </form>
            </FormProvider>
            <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_STATS_360}`}
                  className="prospect-tab__action stats">
                {t('general_view')}
                <PolIcon icon="return" size={14} className="reverse-h"/>
            </Link>
        </div>
    )
}
