import {useFieldArray} from "react-hook-form";
import {useEffect} from "react";

export default function useCollectionTypeField(properties, fieldName, value) {
    const {
        fields,
        append,
        remove,
        replace,
        move,
    } = useFieldArray({name: fieldName});

    useEffect(() => {
        if (value) replace(value);

        // Only run on mount because useFieldArray doesn't get populated on first render
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const appendRow = () => append(
        Object.fromEntries(Object.entries(properties).map(([key, field]) => [key, field.value ?? '']))
    );

    const onDragEnd = ({source, destination}) => {
        if (!destination) return;

        move(source.index, destination.index);
    }

    return {
        fieldsRows: fields,
        appendRow,
        removeRow: remove,
        onDragEnd,
    }
}
