import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {routesBase} from "v4/data/appRoutes";
import useModuleStatisticsListingActionsColumn
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsListingActionsColumn/hooks/useModuleStatisticsListingActionsColumn";
import Confirm from "v4/components/ui/Confirm/Confirm";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ModuleStatisticsListingActionsColumn({statistics, index, setComponentKey}) {
    const {t} = usePolTranslation();
    const {
        rowId,
        toggleConfirmDelete,
        isConfirmDeleteOpen,
        handleDelete,
        isDeleteLoading,
    } = useModuleStatisticsListingActionsColumn(statistics, index, setComponentKey);

    return (
        <>
            <PolButton to={`${routesBase.adminModuleStatistics}/${rowId}`}>
                <PolIcon icon="edit"/>
            </PolButton>
            <PolButton className="btn-danger" onClick={toggleConfirmDelete}>
                <PolIcon icon="trash"/>
            </PolButton>

            <Confirm isOpen={isConfirmDeleteOpen}
                     isLoading={isDeleteLoading}
                     confirmAction={handleDelete}
                     closeAction={toggleConfirmDelete}>
                {t('confirm_delete')}
            </Confirm>
        </>
    )
}
