import useAccessVoter from "v4/hooks/useAccessVoter";

export default function ModuleStatisticsVoter() {
    const {hasRole} = useAccessVoter();

    const canAccessModuleStatistics = () => {
        return hasRole('ROLE_MODULE_STATISTICS');
    }

    return {
        canAccessModuleStatistics
    };
}
