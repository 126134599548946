import React from 'react';
import 'v4/components/ui/ProductsBlockStats/ProductsBlockStats.scss';
import useProductsBlockStats from "v4/components/ui/ProductsBlockStats/hooks/useProductsBlockStats";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import ProductsBlockStatsGraph
    from "v4/components/ui/ProductsBlockStats/components/ProductsBlockStatsGraph/ProductsBlockStatsGraph";
import ErrorBoundary from "components/Error/errorBoundary";
import ProductsBlockStatsItem
    from "v4/components/ui/ProductsBlockStats/components/ProductsBlockStatsItem/ProductsBlockStatsItem";

export default function ProductsBlockStats({productsBlock, statsForIds, filters}) {
    const {
        summary,
        isLoading,
        isPopupOpen,
        openGraphForField,
        graphData,
        isGraphLoading
    } = useProductsBlockStats(productsBlock, statsForIds, filters);

    return (
        <ErrorBoundary>
            <div className="products-block-stats">
                {summary?.map((info, index) => (
                    <ProductsBlockStatsItem key={index}
                                            openGraphForField={openGraphForField}
                                            info={info}
                                            isLoading={isLoading}/>
                ))}
            </div>

            <PolPopup title={productsBlock.label}
                      isOpen={isPopupOpen}
                      closePopup={() => openGraphForField({product: null, field: null})}>
                <ProductsBlockStatsGraph graphData={graphData} isGraphLoading={isGraphLoading}/>
            </PolPopup>
        </ErrorBoundary>
    )
}
