import moment from "moment";
import {getLSItem} from "v4/services/localStorage.service";
import getDatetimeFormat from "v4/utils/getDatetimeFormat";
import {financial} from "v4/utils/index";
import {DATE_COMPARE_TYPE, DATE_TIME_TYPE, DATE_TYPE} from "v4/data/fieldTypes";

export default function getFormattedValue(type, val, config = {}) {
    const finalConfig = {
        dateFormat: getDatetimeFormat(),
        dateTimeFormat: getDatetimeFormat(true),
        toEndOfDay: false,
        keepTime: false,
        polTraduction: null,
        ...config
    };
    const {polTraduction,} = finalConfig;

    if (!type || val === undefined || val === null) return null;

    if ([DATE_TYPE, DATE_TIME_TYPE, 'date', 'datetime'].includes(type)) {
        let newDate;
        switch (val) {
            case 'today':
                newDate = moment()
                break;
            case 'tomorrow':
                newDate = moment().add(1, 'day');
                break;
            case 'yesterday':
                newDate = moment().add(-1, 'day');
                break;
            default:
                newDate = moment(val);
        }

        if (!finalConfig.keepTime) {
            if (finalConfig.toEndOfDay) {
                newDate = newDate.endOf('day')
            } else {
                newDate = newDate.startOf('day')
            }
        }

        if ([DATE_TYPE, 'date'].includes(type)) {
            newDate = newDate.format(finalConfig.dateFormat);
        }

        if ([DATE_TIME_TYPE, 'datetime'].includes(type)) {
            newDate = newDate.format(finalConfig.dateTimeFormat);
        }

        return polTraduction?.(newDate) ?? newDate;
    }

    if (type === DATE_COMPARE_TYPE) {
        const date = new Date();
        const splittedVal = val.split('-');
        date.setUTCMonth(splittedVal[1] - 1, splittedVal[0]);
        date.setUTCFullYear(splittedVal[2]);

        let newDate = moment(date);
        if (finalConfig.toEndOfDay) {
            newDate = newDate.endOf('day')
        } else {
            newDate = newDate.startOf('day')
        }

        return newDate.locale(getLSItem('i18nextLng', 'string')).format('L');
    }

    if (['money'].includes(type)) {
        return financial(val);
    }

    return val;
}
