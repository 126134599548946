import React from 'react';
import './BlockStats.scss';
import {PolIcon} from "components/PolIcon/policon";
import useBlockStats from "v4/components/ui/BlockStats/hooks/useBlockStats";
import BlockStatsValue from "v4/components/ui/BlockStats/components/BlockStatsValue/BlockStatsValue";
import BlockStatsEvolution from "v4/components/ui/BlockStats/components/BlockStatsEvolution/BlockStatsEvolution";
import {createPortal} from "react-dom";
import BlockStatsGraph from "v4/components/ui/BlockStats/components/BlockStatsGraph/BlockStatsGraph";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import ErrorBoundary from "components/Error/errorBoundary";

export default function BlockStats({block, statsForIds, graphsSlotRef, shouldOpenByDefault = false}) {
    const {
        summary,
        isLoading,
        canShowGraph,
        toggleShowGraph,
        showGraphInPopup,
        showGraphInSlot,
        graphData,
        isGraphLoading,
    } = useBlockStats(block, statsForIds, graphsSlotRef, shouldOpenByDefault);

    return (
        <ErrorBoundary>
            <button className={`block-stats ${canShowGraph ? 'block-stats--active' : ''}`}
                    onClick={graphsSlotRef ? showGraphInSlot : showGraphInPopup}>
                {isLoading && <PolIcon icon="circle-notch" className="spinner block-stats__loader"/>}
                <span className="block-stats__title">{block.label}</span>

                <span className="block-stats__content">
                    <BlockStatsValue summary={summary} devise={block.devise}/>
                    <BlockStatsEvolution evolution={summary?.evolution}/>
                </span>
            </button>

            {graphsSlotRef?.current
                ? canShowGraph && createPortal(<BlockStatsGraph graphData={graphData} isGraphLoading={isGraphLoading}/>, graphsSlotRef.current, 'block-stats-graph')
                : (
                    <PolPopup title={block.label}
                              isOpen={canShowGraph}
                              closePopup={toggleShowGraph}>
                        <BlockStatsGraph graphData={graphData} isGraphLoading={isGraphLoading}/>
                    </PolPopup>
                )
            }
        </ErrorBoundary>
    )
}
