import React from 'react';
import GenericTableThead from "v4/components/ui/Table/components/TableThead/TableThead";
import GenericTableTbody from "v4/components/ui/Table/components/TableTbody/TableTbody";

/**
 * @template T
 * @typedef {Record<T, string> & { id?: string }} TableRow
 */

/**
 * @param {TableRow<string>[]} tableRows - Array of table row objects
 * @param {string[] | {key: string, label: string}[]} theadData - Array of strings representing table header data, which are keys in TableRow
 * @param {(rowInfos: TableRow<string>, index: number) => JSX.Element} [actionsColumn] - Optional function to render actions column
 * @param {(key:string) => JSX.Element} [theadDataTrBeforeRender] - Optional function called in the \<tr\> before rendering the thead data
 * @param {(key: string, value: string, index: number) => JSX.Element} [renderCell] - Optional function to render a cell in a row
 * @param {(row:TableRow<string>, index:number) => JSX.Element} [renderRow] - Optional function to render a row
 * @param {boolean} [isLoading] - Flag indicating if the table is in a loading state
 * @param {boolean} [isError] - Flag indicating if there is an error state
 * @param {string} [className] - Optional additional class name for the table
 * @param {(label: string, sortOrder: ('asc' | 'desc')) => void} [onSort] - Optional function to handle sorting
 * @return {JSX.Element}
 */
export default function Table({
                                  tableRows,
                                  theadData,
                                  actionsColumn,
                                  theadDataTrBeforeRender,
                                  renderCell,
                                  renderRow,
                                  isLoading,
                                  isError,
                                  className = "",
                                  onSort,
                              }) {
    return (
        <div className="ui-table-wrapper">
            <table className={`datatable table-v4 ${className}`}>
                <GenericTableThead theadData={theadData}
                                   actionsColumn={actionsColumn}
                                   onSort={onSort}
                                   theadDataTrBeforeRender={theadDataTrBeforeRender}/>
                <GenericTableTbody theadData={theadData}
                                   tableRows={tableRows}
                                   actionsColumn={actionsColumn}
                                   renderRow={renderRow}
                                   renderCell={renderCell}
                                   isLoading={isLoading}
                                   isError={isError}/>
            </table>
        </div>
    )
}
