// This function exists because the collection type value might contain keys that are not present in the
// properties object (fields to be displayed in the collection type field).
import {CHOICE_TYPE, COLLECTION_TYPE} from "v4/data/fieldTypes";

export default function clearCollectionTypeValue(value, properties) {
    if (!value || !properties) return value;

    return value.map(row => {
        return Object.fromEntries(Object.entries(properties).map(([key, field]) => {
            switch (field.type) {
                case COLLECTION_TYPE:
                    return [key, row[key] ? clearCollectionTypeValue(row[key], field.properties) : []];
                case CHOICE_TYPE:
                    return [key, row[key] ?? (field.multiple ? [] : '')];
                default:
                    return [key, row[key] ?? ''];
            }
        }));
    });
}
