import {PolIcon} from "components/PolIcon/policon";
import React, {useEffect} from 'react';
import {Link, useHistory} from "react-router-dom";
import ReactTooltip from "react-tooltip";
import PolPopup from "v4/components/ui/PolPopup/PolPopup";
import {MAILING_SEND} from "v4/data/apiRoutes";
import {routesBase} from "v4/data/appRoutes";
import {PROSPECT} from "v4/data/entities";
import CampaignPopup from "v4/features/front/campaign/components/CampaignPopup/CampaignPopup";
import ListingExport from "v4/features/front/export/components/ListingExport/ListingExport";
import useFetch from "v4/hooks/useFetch";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useToggle from "v4/hooks/useToggle";
import {generateUrl} from "v4/services/api.service";
import {ids} from "v4/utils";
import CampaignVoter from "v4/voters/campaignVoter";
import ModuleStatisticsVoter from "v4/voters/moduleStatisticsVoter";

export default function ProspectsListingTools({alternativeListing, checkedRows}) {
    const {t} = usePolTranslation();
    const {replace} = useHistory();
    const [{data, isLoading}, fetchMailing] = useFetch();
    const {canCreateCampaign} = CampaignVoter();
    const {canAccessModuleStatistics} = ModuleStatisticsVoter();
    const [showCampaignPopup, toggleShowCampaignPopup] = useToggle();

    const isListingEnabled = checkedRows?.length > 0;

    const handleMailing = () => {
        fetchMailing({
            url: generateUrl(MAILING_SEND),
            config: {
                method: 'POST',
                body: JSON.stringify({
                    type: `${PROSPECT}s`, /* TODO: changer contacts en contact côté back */
                    value: checkedRows
                })
            }
        })
    }

    useEffect(() => {
        if (data?.success) {
            replace(`${routesBase.baseNewsletter}/emailing`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const isMapsPage = alternativeListing === 'maps';
    const isStatsPage = alternativeListing === 'statistics';

    return (
        <>
            <li className="listing-header__action"
                data-for="listing-tooltip"
                data-tip={t(isMapsPage ? 'lines_display' : 'map_display')}>
                {isMapsPage
                    ? (
                        <Link to={routesBase.baseProspect}>
                            <PolIcon icon="list"/>
                        </Link>
                    )
                    : (
                        <Link to={`${routesBase.baseProspect}/maps`}>
                            <PolIcon icon="map" className="action__picto"/>
                        </Link>
                    )
                }
            </li>
            {canAccessModuleStatistics() && (
                <li className="listing-header__action"
                    data-for="listing-tooltip"
                    data-tip={t(isStatsPage ? 'lines_display' : 'stats_display')}>
                    {isStatsPage
                        ? (
                            <Link to={routesBase.baseProspect}>
                                <PolIcon icon="list"/>
                            </Link>
                        )
                        : (
                            <Link to={`${routesBase.baseProspect}/statistics`}>
                                <PolIcon icon="calculator" className="action__picto"/>
                            </Link>
                        )
                    }
                </li>
            )}
            <ReactTooltip id="listing-tooltip" effect="solid"/>

            <ListingExport selectedItems={checkedRows} entity={PROSPECT}/>

            <li className="listing-header__action dropdown">
                <PolIcon icon="actions"
                         className="action__picto action__dropdown__Btn"/>
                <ul className="listing-header__action__dropdown">
                    <li className={`listing-header__action__item ${(!isListingEnabled || isLoading) ? 'listing-header__action__item__disabled' : ''}`}
                        onClick={handleMailing}>
                        <p>{t('send_emailing')}</p>
                        {isLoading && <PolIcon icon="circle-notch" className="spinner"/>}
                    </li>

                    {
                        canCreateCampaign() && (
                            <>
                                <li className={`listing-header__action__item${checkedRows.length > 0 ? '' : ' listing-header__action__item__disabled'}`}
                                    onClick={toggleShowCampaignPopup}>
                                    <p>{t("send_campaign")}</p>
                                </li>
                                <PolPopup isOpen={showCampaignPopup}
                                          closePopup={toggleShowCampaignPopup}
                                          title={t('create_campaign')}>
                                    <CampaignPopup selectedIds={checkedRows}
                                                   type="prospects"
                                                   toggleShow={toggleShowCampaignPopup}/>
                                </PolPopup>
                            </>
                        )
                    }

                    <li className={`listing-header__action__item ${!isListingEnabled ? 'listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseContact,
                            state: {[ids(PROSPECT)]: checkedRows}
                        }}>
                            <p>{t('research_associated_contacts')}</p>
                        </Link>
                    </li>

                    <li className={`listing-header__action__item ${!isListingEnabled ? 'listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseTask,
                            state: {[ids(PROSPECT)]: checkedRows}
                        }}>
                            <p>{t('research_associated_actions')}</p>
                        </Link>
                    </li>

                    <li className={`listing-header__action__item ${!isListingEnabled ? 'listing-header__action__item__disabled' : ''}`}>
                        <Link to={{
                            pathname: routesBase.baseQuote,
                            state: {[ids(PROSPECT)]: checkedRows}
                        }}>
                            <p>{t('research_associated_quote')}</p>
                        </Link>
                    </li>
                </ul>
            </li>
        </>
    )
}
