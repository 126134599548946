import ErrorBoundary from "components/Error/errorBoundary";
import React, {useState} from "react";
import {MAIL_TYPE} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ListingEntityLayout from "v4/layouts/ListingEntityLayout/ListingEntityLayout";
import ListingHeaderEntityLayout from "v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout";
import MailsTypesListingActions
    from "v4/pages/front/MailsTypes/components/MailsTypesListing/components/MailsTypesListingActions/MailsTypesListingActions";
import MailsTypesListingRowActions
    from "v4/pages/front/MailsTypes/components/MailsTypesListing/components/MailsTypesListingRowActions/MailsTypesListingRowActions";
import MailsTypesListingTools
    from "v4/pages/front/MailsTypes/components/MailsTypesListing/components/MailsTypesListingTools/MailsTypesListingTools";

export default function MailsTypesListing() {
    const {t} = usePolTranslation();
    const [totalItems, setTotalItems] = useState(null);

    return (
        <>
            <div className="page-list__content">
                <div className="table-container">
                    <ErrorBoundary>
                        <ListingHeaderEntityLayout totalItems={totalItems}
                                                   title='mails'
                                                   pageType='mail-type'
                                                   resultsText={(t(`resultats_elements_mail${(totalItems > 1) ? 's' : ''}`, {count: totalItems}))}
                                                   actions={MailsTypesListingActions}/>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <ListingEntityLayout entity={MAIL_TYPE}
                                             toolsRender={({checkedRows}) => <MailsTypesListingTools checkedRows={checkedRows}/>}
                                             rowActions={MailsTypesListingRowActions}
                                             tableClassName="mail-type"
                                             setTotalItems={setTotalItems}/>
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )
}
