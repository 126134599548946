import React from 'react'
import {createContext, useState} from "react"
import UserService from "api/user/user";
import {randomId} from "v4/utils";

const defaultCustomer = {
    asCustomer: null,
    setAsCustomer: () => {},
    componentKey: null,
    updateComponentKey: () => {}
}

export const AdminContext = createContext(defaultCustomer)

export function AdminProvider({children}) {
    const customerId = UserService.getCustomerId();
    const [asCustomer, setAsCustomer] = useState(customerId);
    const [componentKey, setComponentKey] = useState(randomId());
    const updateComponentKey = () => setComponentKey(randomId());

    return (
        <AdminContext.Provider value={{asCustomer, setAsCustomer, componentKey, updateComponentKey}}>
            {children}
        </AdminContext.Provider>
    )
}
