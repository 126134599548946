import {generateUrl} from "v4/services/api.service";
import {STATISTIC_CONFIG_ITEM} from "v4/data/apiRoutes";
import {useEffect} from "react";
import {randomId} from "v4/utils";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useNotification from "v4/hooks/useNotification";
import useToggle from "v4/hooks/useToggle";
import useFetch from "v4/hooks/useFetch";

export default function useModuleStatisticsListingActionsColumn(statistics, index, setComponentKey) {
    const {t} = usePolTranslation();
    const {addSuccessNotification} = useNotification();
    const [isConfirmDeleteOpen, toggleConfirmDelete] = useToggle();
    const [{isError, isLoading: isDeleteLoading, isFinished}, deleteStatistic] = useFetch();

    const rowId = statistics.results[index].metadata.id;

    const handleDelete = () => {
        deleteStatistic({
            url: generateUrl(STATISTIC_CONFIG_ITEM, {moduleId: rowId}),
            config: {method: 'DELETE'}
        });
    }

    useEffect(() => {
        if (isFinished && !isError) {
            setComponentKey(randomId());
            addSuccessNotification(t('configuration_deleted'));
            toggleConfirmDelete()
        }
    }, [isFinished, isError, setComponentKey, addSuccessNotification, t, toggleConfirmDelete]);

    return {
        rowId,
        isDeleteLoading,
        isConfirmDeleteOpen,
        toggleConfirmDelete,
        handleDelete,
    }
}
