import {captureException, captureMessage} from "@sentry/react";
import * as Sentry from "@sentry/core";
import React, {createContext, useReducer} from 'react';
import useKonamiCode from "v4/hooks/useKonamiCode";
import {randomId} from "v4/utils";
import {useDebugBarContext} from "v4/features/front/debugBar/contexts/DebugBarContext";
import {generateUrl, MIDDLEWARE_URL} from "v4/services/api.service";
import {DEBUG_BAR} from "v4/data/apiRoutes";

export const MAINTENANCE_HEADER = 'X-POL-MAINTENANCE';
export const TRANSACTION_ID_HEADER = 'X-Transaction-Id';

// savedFetch permet de sauvegarder le window.fetch original et de ne pas réappliquer un proxy à ce dernier (voir AuthProvider())
let savedFetch = null;

const defaultAuth = {
    token: null,
    refreshToken: null,
    isMaintenance: null,
}

export const AuthContext = createContext(defaultAuth);

/**
 *
 * @param state
 * @param type
 * @param payload
 * @return {{token: (null|*), refreshToken: (null|*)}}
 */
function authReducer(state, {type, payload}) {
    switch (type) {
        case 'SET_TOKEN':
            return {token: payload.token, refreshToken: payload.refreshToken};
        case 'SET_MAINTENANCE':
            return {...state, isMaintenance: payload};
        default:
            const err = new Error('Aucun type ne correspond');
            captureException(err);
            throw err
    }
}

export function AuthProvider({children}) {
    const {addToken} = useDebugBarContext();
    const [auth, dispatch] = useReducer(authReducer, defaultAuth);
    const isKonamiActivated = useKonamiCode();

    const setToken = (token, refreshToken) => {
        dispatch({type: "SET_TOKEN", payload: {token, refreshToken}});
    };

    const authProvided = {
        ...auth,
        setToken
    }

    // À chaque fois que AuthProvider est mis à jour, il remplace window.fetch par la fonction window.fetch originale
    if (savedFetch) {
        window.fetch = savedFetch;
    } else {
        // Permet de setter savedFetch avec la fonction window.fetch originale une seule fois
        savedFetch = window.fetch;
    }

    // À chaque fois que AuthProvider est mis à jour, il remplace window.fetch qui,
    // maintenant, est la fonction originale par notre proxy. Nécessaire de re-setter
    // à chaque fois pour être sûr d'avoir le bon token
    window.fetch = new Proxy(window.fetch, {
        apply(fetch, that, args) {
            let [url = '', config = {}] = args;

            if (!config.headers) config.headers = {};

            const activeSpan = Sentry.getActiveSpan();
            const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

            // Create `sentry-trace` header
            const sentryTraceHeader = rootSpan
                ? Sentry.spanToTraceHeader(rootSpan)
                : undefined;

            // Create `baggage` header
            const sentryBaggageHeader = rootSpan
                ? Sentry.spanToBaggageHeader(rootSpan)
                : undefined;

            // Set-up Sentry Tracing
            if (sentryTraceHeader) config.headers['sentry-trace'] = sentryTraceHeader;
            if (sentryBaggageHeader) config.headers['baggage'] = sentryBaggageHeader;

            // Set-up X-Transaction-ID
            if (url.match(process.env.REACT_APP_HOST_API)) config.headers[TRANSACTION_ID_HEADER] = `front:${randomId()}`;
            Sentry.setTag('X-Transaction-Id', config.headers[TRANSACTION_ID_HEADER] ?? 'no-transaction-id');

            // Si le lien correspond à l'API (excepté lors de la connexion et de la configuration du client Microsoft Graph)
            if (url.match(process.env.REACT_APP_HOST_API) && !url.match(/login/g) && !url.endsWith('/module_microsoft_graph/customer_configuration')) {
                // On ajoute le delcache=true pour debug si le konami code est activé
                if (isKonamiActivated) {
                    const _url = new URL(url);
                    _url.searchParams.set('delcache', 'true');
                    url = _url.toString();
                }

                // On ajoute le token
                if (authProvided.token) {
                    config.headers["X-POL-AUTH"] = 'Bearer ' + authProvided.token
                }
            }

            const result = fetch.apply(that, [url, config]);

            result
                /* Ce then permet de gérer la réponse avant de l'envoyer au reste du code.
                Peut servir lors de la gestion d'un retour
                d'un refresh_token lorsque le token a expiré. */
                .then((res) => {
                    if (process.env.REACT_APP_ENV === 'development') {
                        const debugToken = res.headers.get('X-Debug-Token');
                        if (debugToken && !url.match(`${MIDDLEWARE_URL}${generateUrl(DEBUG_BAR, {token: debugToken}, false)}`)) {
                            setTimeout(() => addToken(debugToken, Date.now()), 100); // L'appel est parfois trop rapide pour avoir les infos et l'on a une 404, ce mini délai permet de ne pas avoir ce problème
                        }
                    }

                    if (!res.ok && res.status !== 401) {
                        captureMessage(`Error received when requesting API`, {
                            extra: {
                                request: {
                                    url,
                                    config: JSON.stringify(config),
                                },
                                response: {
                                    status: res.status,
                                    statusText: res.statusText,
                                    body: res.body,
                                },
                            }
                        });
                    }

                    if (res.headers.has(MAINTENANCE_HEADER)) {
                        dispatch({type: 'SET_MAINTENANCE', payload: res.headers.get(MAINTENANCE_HEADER) === 'true'});
                    }
                })
                /* Le catch permet de ne pas renvoyer une erreur au front. */
                .catch((err) => {
                    if (err.name === 'AbortError') return;

                    return captureException(err, {
                        extra: {
                            request: {
                                url,
                                config,
                            }
                        }
                    })
                });

            return result;
        }
    });

    return (
        <AuthContext.Provider value={authProvided}>
            {children}
        </AuthContext.Provider>
    )
}
