import React from 'react';
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import useFieldWrapperLabel from "v4/components/form/FieldWrapper/hooks/useFieldWrapperLabel";
import Field from "v4/components/form/Field/Field";
import toKebabCase from "v4/utils/toKebabCase";

export default function FieldWrapper({field, name, isLabelActive = false, children}) {
    const {t} = usePolTranslation();
    const {
        isLabelActive: isLabelActiveFromHook,
        fieldPrefix,
        fieldLabel,
        fieldType,
        fieldWrapperRef,
    } = useFieldWrapperLabel(field);

    if (!field) return null;

    return (
        <div className={`input-field-wrapper ${toKebabCase(fieldType)}`} ref={fieldWrapperRef}>
            {children ? (
                typeof children === 'function'
                    ? children({fieldPrefix})
                    : children
            ) : (
                <Field prefix={fieldPrefix} {...field} name={name}/>
            )}
            {fieldLabel && (
                <label htmlFor={`${fieldPrefix}${name}`}
                       className={(isLabelActive || isLabelActiveFromHook) ? 'active' : ''}>
                    {t(fieldLabel)}
                </label>
            )}
        </div>
    )
}
