import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const UP = 'up';
const DOWN = 'down';

export default function ProductsBlockStatsContentItem({product, info, openGraphForField}) {
    const {t} = usePolTranslation();

    if (!info) return null;

    const {field, label, value, unit, evolution} = info;

    return (
        <tr className="products-block-stats__content-item" onClick={() => openGraphForField({product, field})}>
            <td className="products-block-stats__content-item__cell is-title">{t(label)}</td>
            <td className="products-block-stats__content-item__cell is-value">{value} {unit ?? ''}</td>
            <td className="products-block-stats__content-item__cell is-evolution">
                {evolution === UP && <PolIcon icon="trending-up"/>}
                {evolution === DOWN && <PolIcon icon="trending-down"/>}
            </td>
        </tr>
    )
}
