import i18n from 'i18next';
import {initReactI18next} from "react-i18next";
import detector from "i18next-browser-languagedetector";

import message_en from './locales/en-GB/pol-en-GB';
import message_fr from './locales/fr-FR/pol-fr-FR';

i18n
    .use(detector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: message_en
            },
            fr: {
                translation: message_fr
            }
        },
        //debug: true,
        preload: true,
        fallbackLng: "FR",
        nsSeparator: false, // allow to use ":" in keys like "prefix:text"
        keySeparator: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
