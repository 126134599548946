import React from 'react';
import {Bar} from "react-chartjs-2";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";

export default function BlockStatsGraph({graphData, isGraphLoading}) {

    if (isGraphLoading) return <div className="block-stats__graph"><InlineLoader/></div>;

    if (!graphData) return null;

    return (
        <div className="block-stats__graph">
            <Bar data={{...graphData.data}}
                 options={{...graphData.options}}/>
        </div>
    )
}
