export default function lightenDarkenHexColor(hexColor, amountInPercent) {
    const hexColorValue = hexColor.slice(1);

    const num = parseInt(hexColorValue, 16);
    let r = (num >> 16) + amountInPercent;
    let g = ((num >> 8) & 0x00FF) + amountInPercent;
    let b = (num & 0x0000FF) + amountInPercent;

    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    const newColor = (r << 16) | (g << 8) | b;
    return `#${newColor.toString(16).padStart(6, '0')}`;
}
