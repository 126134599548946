import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import ProductsBlockStatsContent
    from "v4/components/ui/ProductsBlockStats/components/ProductsBlockStatsContent/ProductsBlockStatsContent";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ProductsBlockStatsItem({info, isLoading, openGraphForField}) {
    const {t} = usePolTranslation();
    const {label, reference, fields} = info

    return (
        <div className="products-block-stats__item">
            {isLoading && (
                <PolIcon icon="circle-notch" className="products-block-stats__loader spinner"/>
            )}
            <p className="products-block-stats__title">{t(label)}</p>
            <p className="products-block-stats__tags">
                <span className="products-block-stats__tag">
                    <span>{t('ref')} : </span> <span>{reference}</span>
                </span>
            </p>

            <ProductsBlockStatsContent fields={fields} product={label} openGraphForField={openGraphForField}/>
        </div>
    )
}
