import React from 'react';
import TableStats from "v4/components/ui/TableStats/TableStats";
import './TablesStats.scss';

export default function TablesStats({tables, statsForIds}) {
    return (
        <div className="tables-stats">
            {tables.map(table => (
                <TableStats key={table.id} table={table} statsForIds={statsForIds}/>
            ))}
        </div>
    )
}
