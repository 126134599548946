import React from 'react';
import ProductsBlockStatsContentItem
    from "v4/components/ui/ProductsBlockStats/components/ProductsBlockStatsContentItem/ProductsBlockStatsContentItem";

export default function ProductsBlockStatsContent({product, fields, openGraphForField}) {
    return (
        <div className="products-block-stats__content">
            <table>
                <tbody>
                {fields
                    ? fields.map((field, index) => (
                        <ProductsBlockStatsContentItem key={index}
                                                       product={product}
                                                       info={field}
                                                       openGraphForField={openGraphForField}/>
                    ))
                    : <tr>
                        <td>-</td>
                    </tr>
                }
                </tbody>
            </table>
        </div>
    )
}
