import React from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import {Bar} from "react-chartjs-2";
import 'v4/components/ui/ProductsBlockStats/components/ProductsBlockStatsGraph/ProductsBlockStatsGraph.scss';

export default function ProductsBlockStatsGraph({graphData, isGraphLoading}) {
    if (isGraphLoading) return <div className="products-block-stats__graph"><InlineLoader/></div>;

    if (!graphData?.data) return <div className="products-block-stats__graph"/>;

    return (
        <div className="products-block-stats__graph">
            <Bar data={{...graphData.data}}
                 options={{...graphData.options}}/>
        </div>
    )
}
