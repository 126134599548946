import {useEffect} from "react";
import useFetch from "v4/hooks/useFetch";
import lightenDarkenHexColor from "v4/utils/lightenDarkenHexColor";

export default function usePieStats(pie, statsForIds) {
    const [{data, isLoading}, fetchGraph] = useFetch();

    useEffect(() => {
        fetchGraph({
            url: pie.links.summary.url,
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }
        })
    }, [pie.links.summary.url, fetchGraph, statsForIds]);

    return {
        isLoading,
        graphData: data?.summary ? prepareSummaryForGraphJS(data.summary) : null,
    }
}

function prepareSummaryForGraphJS(summary) {
    const {labels, dataset = []} = summary ?? {};

    return {
        data: {
            labels,
            datasets: [{
                data: dataset,
                backgroundColor: getBackgroundColors(dataset.length),
            }]
        },
        options: {},
    };
}

function getBackgroundColors(length, initialColor = '#9785f2') {
    const colors = [];

    for (let i = 0; i < length; i++) {
        colors.push(lightenDarkenHexColor(initialColor, i * -40));
    }

    return colors;
}
