import ErrorBoundary from "components/Error/errorBoundary";
import React, {useState} from "react";
import {TASK} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ListingEntityLayout from "v4/layouts/ListingEntityLayout/ListingEntityLayout";
import ListingHeaderEntityLayout from "v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout";
import TasksListingRowActions
    from "v4/pages/front/Tasks/components/TasksListing/components/TasksListingRowActions/TasksListingRowActions";
import TasksListingTools
    from "v4/pages/front/Tasks/components/TasksListing/components/TasksListingTools/TasksListingTools";

export default function TasksListing({tab}) {
    const {t} = usePolTranslation();
    const [totalItems, setTotalItems] = useState(null);

    return (
        <>
            <div className="page-list__content">
                <div className="table-container">
                    <ErrorBoundary>
                        <ListingHeaderEntityLayout totalItems={totalItems}
                                                   title={tab?.title ?? t('actions')}
                                                   pageType='actions'
                                                   resultsText={
                                                       t(`resultats_elements_action${(totalItems > 1) ? 's' : ''}`, {count: totalItems})
                                                   }/>
                    </ErrorBoundary>
                    <ErrorBoundary>
                        <ListingEntityLayout entity={TASK}
                                             toolsRender={({checkedRows}) => <TasksListingTools checkedRows={checkedRows}/>}
                                             rowActions={<TasksListingRowActions tab={tab}/>}
                                             tableClassName="actions"
                                             setTotalItems={setTotalItems}
                                             tab={tab}/>
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )
}
