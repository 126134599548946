import {captureException} from "@sentry/react";
import React from 'react';
import {withPolTranslation} from "v4/hooks/usePolTranslation";
import {PolIcon} from "../PolIcon/policon";
import PolButton from "v4/components/ui/PolButton/PolButton";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    componentDidCatch(error, info) {
        captureException(error)
        this.setState({
            hasError: true
        });
    }

    refresh = () => {
        this.setState({
            hasError: false,
        });
    }

    render() {
        const {t, className = '', canRetry = false, children} = this.props;
        const {hasError} = this.state;

        if (hasError) {
            return (
                <div className={`${className} page-error-component`}>
                    <div className="page-error-component__content">
                        <PolIcon icon="exclamation-circle" className="error-icon"/>
                        <p className="error-text mb-3">{t("error_content_loaded")}</p>
                        {canRetry && (
                            <PolButton onClick={this.refresh}>
                                {t('retry')}
                            </PolButton>
                        )}
                    </div>
                </div>
            );
        }

        return children;
    }
}

export default withPolTranslation(ErrorBoundary);
