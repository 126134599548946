import React from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import useProspectsListingStats
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingStats/hooks/useProspectsListingStats";
import BlocksStats from "v4/components/ui/BlocksStats/BlocksStats";
import TablesStats from "v4/components/ui/TablesStats/TablesStats";
import PiesStats from "v4/components/ui/PiesStats/PiesStats";
import './ProspectsListingStats.scss';

export default function ProspectsListingStats({checkedIds, isLoading}) {
    const {
        modules,
        areProspectsLoading,
        statsForIds,
    } = useProspectsListingStats(checkedIds, isLoading);

    if (!modules && areProspectsLoading) return <InlineLoader/>;

    if (!modules) return null;

    return (
        <div className="prospects-listing__stats">
            {modules.blocks && <BlocksStats blocks={modules.blocks} statsForIds={statsForIds} shouldShowGraphInPopup/>}
            {modules.pies && <PiesStats pies={modules.pies} statsForIds={statsForIds}/>}

            <div className="full-width">
                {modules.tables && <TablesStats tables={modules.tables} statsForIds={statsForIds}/>}
            </div>
        </div>
    )
}
