import useFetch from "v4/hooks/useFetch";
import {useEffect, useState} from "react";
import {prepareDetailsForGraphJS} from "v4/components/ui/BlockStats/hooks/useBlockStats";

export default function useProductsBlockStats(productsBlock, statsForIds, filters) {
    const [graphForField, openGraphForField] = useState({product: null, field: null});
    const [{data, isLoading}, fetchStats] = useFetch();
    const [{data: graphData, isLoading: isGraphLoading}, fetchGraphStats] = useFetch();
    const [graphDataCache, setGraphDataCache] = useState({});

    const summary = data?.summary;

    useEffect(() => {
        const url = new URL(productsBlock.links.summary.url);
        if (filters.GET) {
            Object.entries(filters.GET).forEach(([key, value]) => {
                if (value.length === 0) return;

                url.searchParams.append(key, String(value));
            });
        }

        fetchStats({
            url: url.toString(),
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }
        });
    }, [productsBlock.links.summary.url, fetchStats, statsForIds, filters]);

    useEffect(() => {
        if (!graphForField.field || graphDataCache[graphForField.product]?.[graphForField.field]) return;

        const url = new URL(productsBlock.links.details.url);
        url.searchParams.append('product', graphForField.product);
        url.searchParams.append('field', graphForField.field);

        fetchGraphStats({
            url: url.toString(),
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }
        });
    }, [fetchGraphStats, graphDataCache, graphForField.field, graphForField.product, productsBlock.links.details.url, statsForIds]);

    useEffect(() => {
        if (graphData?.details && graphForField.field && !graphDataCache[graphForField.product]?.[graphForField.field]) {
            setGraphDataCache(previousGraphDataCache => ({
                ...previousGraphDataCache,
                [graphForField.product]: {
                    ...previousGraphDataCache[graphForField.product],
                    [graphForField.field]: prepareDetailsForGraphJS(graphData.details, productsBlock.devise)
                }
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [graphData, productsBlock.devise]);

    return {
        summary,
        isLoading,
        isPopupOpen: !!graphForField.field,
        openGraphForField,
        graphData: graphDataCache[graphForField.product]?.[graphForField.field],
        isGraphLoading,
    }
}
