import React from 'react';

export default function BlockStatsEvolution({evolution}) {
    if (!evolution) return <span className="block-stats__evolution">-</span>;

    return (
        <span className={`block-stats__evolution ${evolution >= 0 ? 'positive' : 'negative'}`}>
            {evolution > 0 && '+'}
            {evolution.toLocaleString('fr-FR', {maximumFractionDigits: 2})}%
        </span>
    )
}
