import React from 'react';
import useModuleStatisticsListing
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsListing/hooks/useModuleStatisticsListing";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import Table from "v4/components/ui/Table/Table";
import tableResultsResponseToTableRows from "v4/utils/tableResultsResponseToTableRows";
import ModuleStatisticsListingActionsColumn
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsListingActionsColumn/ModuleStatisticsListingActionsColumn";

export default function ModuleStatisticsListing({setCardHeaderTools}) {
    const {
        statistics,
        isFirstRenderLoading,
        isLoading,
        setComponentKey,
    } = useModuleStatisticsListing(setCardHeaderTools);

    if (isFirstRenderLoading) return <InlineLoader/>

    if (!statistics) return null;

    return (
        <Table tableRows={tableResultsResponseToTableRows(statistics.results)}
               theadData={statistics.headers}
               actionsColumn={(row, index) => (
                   <ModuleStatisticsListingActionsColumn statistics={statistics}
                                                         index={index}
                                                         setComponentKey={setComponentKey}/>
               )}
               isLoading={isLoading}/>
    )
}
