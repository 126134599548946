import React from 'react';
import Table from "v4/components/ui/Table/Table";
import useTableStats from "v4/components/ui/TableStats/hooks/useTableStats";
import tableResultsResponseToTableRows from "v4/utils/tableResultsResponseToTableRows";
import 'v4/components/ui/TableStats/TableStats.scss';
import ErrorBoundary from "components/Error/errorBoundary";

export default function TableStats({table, statsForIds}) {
    const {
        details,
        isLoading,
    } = useTableStats(table, statsForIds);

    return (
        <ErrorBoundary>
            <div className="table-stats">
                <div className="table-stats__tab">
                    {table.label}
                </div>
                <div className="table-stats__content">
                    <Table className="table-stats__table"
                           theadData={details?.headers ?? table.fields}
                           isLoading={isLoading}
                           tableRows={tableResultsResponseToTableRows(details?.results)}/>
                </div>
            </div>
        </ErrorBoundary>
    )
}
