import useFetch from "v4/hooks/useFetch";
import {useEffect} from "react";

export default function useAutoFetch(url, dependencies) {
    const [state, fetch] = useFetch();

    useEffect(() => {
        fetch({url});
    }, [fetch, url, dependencies]);

    return state;
}
