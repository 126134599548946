import React from 'react';
import './CollectionTypeField.scss';
import useCollectionTypeField
    from "v4/components/form/Field/components/CollectionTypeField/hooks/useCollectionTypeField";
import CollectionTypeFieldRow
    from "v4/components/form/Field/components/CollectionTypeField/components/CollectionTypeFieldRow/CollectionTypeFieldRow";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {PolIcon} from "components/PolIcon/policon";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

export default function CollectionTypeField({
                                                label,
                                                name,
                                                value,
                                                properties,
                                                hasError,
                                                collectionTypeConstraints
                                            }) {
    const {t} = usePolTranslation();
    const {
        fieldsRows,
        appendRow,
        removeRow,
        onDragEnd,
    } = useCollectionTypeField(properties, name, value);


    return (
        <fieldset className={`collection-type-field ${hasError ? 'is-error' : ''}`}>
            {label && <legend>{t(label)}</legend>}
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="collection-type-field">
                    {provided => (
                        <div className="collection-type-field__rows"
                             ref={provided.innerRef}
                             {...provided.droppableProps}>
                            {fieldsRows.map(({id, ...fieldsRow}, index) => (
                                <CollectionTypeFieldRow key={id}
                                                        id={id}
                                                        properties={properties}
                                                        collectionTypeFieldName={name}
                                                        index={index}
                                                        fieldsRow={fieldsRow}
                                                        removeRow={collectionTypeConstraints.allowDelete ? removeRow : undefined}/>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            {collectionTypeConstraints.allowAdd && (
                <PolButton onClick={appendRow}>
                    <PolIcon icon="plus"/>
                    {t('add')}
                </PolButton>
            )}
        </fieldset>
    )
}
