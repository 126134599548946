import React from 'react';
import PolButton from "v4/components/ui/PolButton/PolButton";
import {PolIcon} from "components/PolIcon/policon";
import PolDropdown from "v4/components/ui/PolDropdown/PolDropdown";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";

export const STATISTIC_MODULE_BLOCK_TYPE = 'block';
export const STATISTIC_MODULE_TABLE_TYPE = 'table';
export const STATISTIC_MODULE_PRODUCT_TYPE = 'product';
export const STATISTIC_MODULE_TURNOVER_TYPE = 'turnover';

const STATISTIC_MODULES_TYPES = [
    STATISTIC_MODULE_BLOCK_TYPE,
    STATISTIC_MODULE_TABLE_TYPE,
    STATISTIC_MODULE_PRODUCT_TYPE,
    STATISTIC_MODULE_TURNOVER_TYPE,
];

export default function ModuleStatisticsTools({isProductStatsAlreadyCreated}) {
    const {t} = usePolTranslation();

    const statisticFilter = (moduleType) => {
        return !(moduleType === STATISTIC_MODULE_PRODUCT_TYPE && isProductStatsAlreadyCreated);
    }

    return (
        <PolDropdown isHoverable
                     anchorInline="right"
                     summaryRender={({isHovered}) => (
                         <PolButton isActive={isHovered}>
                             <PolIcon icon="plus"/> {t('add')}
                         </PolButton>
                     )}
                     detailsRender={() => (
                         <div className="module-statistics-tools__list">
                             {STATISTIC_MODULES_TYPES.filter(statisticFilter).map(moduleType => (
                                 <Link key={moduleType}
                                       to={`${routesBase.adminModuleStatistics}/new?moduleType=${moduleType}`}
                                       className="module-statistics-tools__item">
                                     {t(`statistic:new_${moduleType}`)}
                                 </Link>
                             ))}
                         </div>
                     )}
        />
    )
}
