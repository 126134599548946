import {captureException} from "@sentry/react";
import React, {useContext, useEffect, useState} from 'react';
import apiService from "v4/services/api.service";
import useFetch from "v4/hooks/useFetch";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import {MODULE_UPDATE} from "v4/data/apiRoutes";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import UserService from "api/user/user";
import {AdminContext} from "v4/contexts/AdminContext";

export default function Module({moduleInfos: {id, name, description, role, isEnabled, isConfigurable}, customerId}) {
    const {t} = usePolTranslation();
    const [isActive, setIsActive] = useState(isEnabled);
    const [{data, isError, isFinished}, hookFetch] = useFetch();
    const {addSuccessNotification, addErrorNotification} = useNotification();
    const {updateComponentKey} = useContext(AdminContext);

    useEffect(() => {
        if (isFinished && isError) {
            setIsActive(oldIsActive => !oldIsActive);
            addErrorNotification(t("fetch_post_error"));
        }
        if (isFinished && !isError) {
            addSuccessNotification(t("module_update_customer"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, isFinished])

    useEffect(() => {
        if (data?.data && !isError) {
            const newModulesRoles = data.data.modules.map(({role}) => role);
            UserService.setModulesRoles(newModulesRoles);
            updateComponentKey(); // Refresh the menuGroupAdminV4 in sidebar
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, isError])

    const handleCheck = (e) => {
        setIsActive(e.currentTarget.checked);
        hookFetch({
            url: apiService.generateUrl(MODULE_UPDATE, {id: customerId}),
            config: {
                method: 'PUT',
                body: JSON.stringify({
                    moduleId: id
                })
            }
        })
    }

    let imgSrc;
    try {
        imgSrc = require(`v4/features/admin/modules/assets/${id}.png`);
    } catch (e) {
        captureException(e, {
            extra: {
                id
            }
        });
        imgSrc = require(`v4/features/admin/modules/assets/default.png`);
    }

    return (
        <div className={"module-card" + (isActive ? " active" : "")}>
            <p className="module-card__title" title={name}>{t(name)}</p>
            <input className="module-card__checkbox" id={name} type={"checkbox"} onChange={handleCheck}
                   checked={isActive}/>
            <div className="module-card__img">
                <img src={imgSrc} alt="Décoration"/>
                <p className="module-card__role">{role}</p>
            </div>
            <p className="module-card__desc">{description}</p>
            {/* @todo PV-1002 : mise en forme à revoir */}
            {isConfigurable && isActive &&
                <Link to={`${routesBase.adminModuleConfiguration}/${customerId}/${id}/`} className="btn btn-secondary">{t('configure')}</Link>
            }
        </div>
    )
}
