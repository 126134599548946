/* TAB_NAME = 'tab-slug-in-url' */

// TASKS
import {CONTACT, TASK, QUOTE} from "v4/data/entities";

export const TAB_OPEN_TASKS = 'actions';
export const TAB_CLOSED_TASKS = 'historique';
export const TAB_OLD_MAILS_TASKS = 'historique-mails';
export const TAB_RECEIVED_MAILS_TASKS = 'received-mails';

// QUOTES
export const TAB_QUOTES = 'devis-offres';
export const TAB_WON_QUOTES = 'affaires';

// CONTACTS
export const TAB_CONTACTS = 'contacts';

// STATS 360
export const TAB_STATS_360 = 'stats-360';

// Correspondance between tab name and entity
export const TAB_NAME_ENTITY = {
    [TAB_OPEN_TASKS]: TASK,
    [TAB_CLOSED_TASKS]: TASK,
    [TAB_OLD_MAILS_TASKS]: TASK,
    [TAB_RECEIVED_MAILS_TASKS]: TASK,
    [TAB_QUOTES]: QUOTE,
    [TAB_WON_QUOTES]: QUOTE,
    [TAB_CONTACTS]: CONTACT,
}

// Export all tab names
export default {
    TAB_CONTACTS,
    TAB_OPEN_TASKS,
    TAB_CLOSED_TASKS,
    TAB_OLD_MAILS_TASKS,
    TAB_RECEIVED_MAILS_TASKS,
    TAB_QUOTES,
    TAB_WON_QUOTES,
    TAB_STATS_360,
};
