import React, {useState} from "react";
import {PROSPECT} from "v4/data/entities";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import ListingEntityLayout from "v4/layouts/ListingEntityLayout/ListingEntityLayout";
import ListingHeaderEntityLayout from "v4/layouts/ListingHeaderEntityLayout/ListingHeaderEntityLayout";
import ProspectsListingActions
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingActions/ProspectsListingActions";
import ProspectsListingMaps
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingMaps/ProspectsListingMaps";
import ProspectsListingRowActions
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingRowActions/ProspectsListingRowActions";
import ProspectsListingTools
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingTools/ProspectsListingTools";
import {useParams} from "react-router-dom";
import ProspectsListingStats
    from "v4/pages/front/Prospects/components/ProspectsListing/components/ProspectsListingStats/ProspectsListingStats";

export default function ProspectsListing({tab}) {
    const {t} = usePolTranslation();
    const [totalItems, setTotalItems] = useState(null);
    const {alternativeListing} = useParams();

    return (
        <div className="page-list__content">
            <div className="table-container">
                <ListingHeaderEntityLayout totalItems={totalItems}
                                           title={tab?.title ?? t('comptes_prospects_clients')}
                                           pageType='prospects'
                                           resultsText={(t(`resultats_elements_prospect${(totalItems > 1) ? 's' : ''}`, {count: totalItems}))}
                                           actions={ProspectsListingActions}/>
                <ListingEntityLayout entity={PROSPECT}
                                     toolsRender={({checkedRows}) => <ProspectsListingTools alternativeListing={alternativeListing} checkedRows={checkedRows}/>}
                                     rowActions={ProspectsListingRowActions}
                                     tableClassName="prospects"
                                     alternateDisplay={getAlternateDisplayComponent(alternativeListing)}
                                     alternateDisplayOptions={{alternativeListing}}
                                     setTotalItems={setTotalItems}
                                     tab={tab}/>
            </div>
        </div>
    )
}

function getAlternateDisplayComponent(alternativeListing) {
    switch (alternativeListing) {
        case 'maps':
            return ({entitiesList, isLoading}) => (
                <ProspectsListingMaps entitiesList={entitiesList} isLoading={isLoading}/>
            );
        case 'statistics':
            return ({checkedIds, isLoading}) => (
                <ProspectsListingStats checkedIds={checkedIds} isLoading={isLoading}/>
            );
        default:
            return null;
    }
}
