import React from 'react';
import PieStats from "v4/components/ui/PieStats/PieStats";
import './PiesStats.scss';

export default function PiesStats({pies, statsForIds}) {
    return (
        <div className="pies-stats">
            {pies.map((pie, index) => (
                <PieStats key={pie.id ?? index} pie={pie} statsForIds={statsForIds}/>
            ))}
        </div>
    )
}
