import useFetch from "v4/hooks/useFetch";
import {useEffect, useState} from "react";
import useToggle from "v4/hooks/useToggle";

export default function useBlockStats(block, statsForIds, graphsSlotRef, shouldOpenByDefault) {
    const [canShowGraph, toggleShowGraph] = useToggle();
    const [{data: dataSummary, isLoading}, fetchSummary] = useFetch();
    const [{data: dataGraph, isLoading: isGraphLoading}, fetchGraph] = useFetch();
    const [graphDataCache, setGraphDataCache] = useState(null);

    const summary = dataSummary?.summary;

    useEffect(() => {
        fetchSummary({
            url: block.links.summary.url,
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }});
    }, [block.links.summary.url, fetchSummary, statsForIds]);

    useEffect(() => {
        const graphsSlotElement = graphsSlotRef?.current;
        const hideGraph = ({detail}) => {
            if (detail.id === block.id) return;

            toggleShowGraph(false);
        }

        if (graphsSlotElement) {
            graphsSlotElement.addEventListener('showBlockGraph', hideGraph);

            return () => graphsSlotElement.removeEventListener('showBlockGraph', hideGraph);
        }
    }, [block.id, graphsSlotRef, toggleShowGraph]);

    const showGraphInPopup = () => {
        toggleShowGraph(true);

        if (graphDataCache) return;

        fetchGraph({
            url: block.links.details.url,
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }
        });
    }

    const showGraphInSlot = () => {
        if (graphsSlotRef?.current) {
            graphsSlotRef.current.dispatchEvent(new CustomEvent('showBlockGraph', {detail: block}));
        }
        toggleShowGraph(true);

        if (graphDataCache) return;

        fetchGraph({url: block.links.details.url});
    }

    useEffect(() => {
        if (dataGraph?.details) {
            setGraphDataCache(prepareDetailsForGraphJS(dataGraph.details, block.devise));
        }
    }, [block.devise, dataGraph]);

    useEffect(() => {
        if (shouldOpenByDefault) showGraphInSlot();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        isLoading,
        summary,
        canShowGraph,
        toggleShowGraph,
        showGraphInPopup,
        showGraphInSlot,
        graphData: graphDataCache,
        isGraphLoading,
    }
}

export function prepareDetailsForGraphJS(details, devise) {
    const {labels, dataset} = details ?? {};

    return {
        data: {
            labels,
            datasets: dataset.map(({label, data}, index) => ({
                label,
                data,
                backgroundColor: getBackgroundColor(index),
            })),
        },
        options: {
            tooltips: {
                callbacks: {
                    label: ({value}) => {
                        const valueAsNumber = Number(value);
                        if (Number.isNaN(valueAsNumber)) return value;

                        return valueAsNumber.toLocaleString('fr-FR', {maximumFractionDigits: 2}) + ` ${devise ?? ''}`
                    }
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        callback: (value) => value.toLocaleString('fr-FR', {maximumFractionDigits: 2}) + ` ${devise ?? ''}`
                    }
                }],
            },
        }
    };
}

function getBackgroundColor(index) {
    const colors = [
        '#9785f2',
        '#ceefec',
        '#ffc9cd',
    ];

    return colors[index % colors.length];
}
