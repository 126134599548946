import UserService from "api/user/user";
import {PolIcon} from "components/PolIcon/policon";
import React from 'react';
import {Link} from "react-router-dom";
import {useFrontContext} from "v4/contexts/FrontContext";
import {routesBase} from "v4/data/appRoutes";
import {
    TAB_STATS_360,
    TAB_CLOSED_TASKS,
    TAB_CONTACTS,
    TAB_OLD_MAILS_TASKS,
    TAB_OPEN_TASKS,
    TAB_QUOTES,
    TAB_RECEIVED_MAILS_TASKS,
    TAB_WON_QUOTES
} from "v4/data/tabNames";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import 'v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectTabsSwitcherNavigation/ProspectTabsSwitcherNavigation.scss';
import ModuleStatisticsVoter from "v4/voters/moduleStatisticsVoter";

export default function ProspectTabsSwitcherNavigation({prospectId, currentTabName}) {
    const {t} = usePolTranslation();
    const {goBackToListing} = useFrontContext();
    const {canAccessModuleStatistics} = ModuleStatisticsVoter();

    return (
        <ul className="prospect__tab__navigation">
            <li className={getClassName(currentTabName, TAB_CONTACTS)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_CONTACTS}`}>
                    <PolIcon icon="contact"/>
                    <span>{t('contacts', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_OPEN_TASKS)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_OPEN_TASKS}`}>
                    <PolIcon icon="tasks"/>
                    <span>{t('current_tasks', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_CLOSED_TASKS)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_CLOSED_TASKS}`}>
                    <PolIcon icon="tasks"/>
                    <span>{t('historique_actions_terminees', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_QUOTES)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_QUOTES}`}>
                    <PolIcon icon="quote"/>
                    <span>{t('devis_offres', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_WON_QUOTES)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_WON_QUOTES}`}>
                    <PolIcon icon="business"/>
                    <span>{t('affaires', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_OLD_MAILS_TASKS)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_OLD_MAILS_TASKS}`}>
                    <PolIcon icon="mail-historical"/>
                    <span>{t('historique_mails', {}, true)}</span>
                </Link>
            </li>
            <li className={getClassName(currentTabName, TAB_RECEIVED_MAILS_TASKS)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_RECEIVED_MAILS_TASKS}`}>
                    <PolIcon icon="mail-historical"/>
                    <span>{t('mails_recus', {}, true)}</span>
                </Link>
            </li>
            {canAccessModuleStatistics() && (
                <li className={getClassName(currentTabName, TAB_STATS_360)}>
                    <Link to={`${routesBase.baseProspect}/${prospectId}/${TAB_STATS_360}`}>
                        <PolIcon icon="calculator"/>
                        <span>{t('360_view', {}, true)}</span>
                    </Link>
                </li>
            )}
            {getCustomTabs(currentTabName, prospectId, t)}
            <li className="tab-close" onClick={goBackToListing}>
                <PolIcon icon="close"/>
            </li>
        </ul>
    )
}

function getCustomTabs(currentTabName, prospectId, t) {
    const tabs = UserService.getTabs();
    return tabs.map(tab => {
        if (tab.searchType === 'prospect' || tab.isDisabledDisplaySublist) return null;

        if ('subMenu' in tab) {
            const hasActiveSubTab = tab.subMenu.some(subMenu => subMenu.slug === currentTabName);
            return (
                <li key={tab.groupLabel} className={`group ${hasActiveSubTab ? 'active' : ''}`}>
                    <span className="group-label">{t(tab.groupLabel)}</span>
                    <ul className="sub-menu">
                        {tab.subMenu.map(subMenu => {
                            if (subMenu.searchType === 'prospect' || subMenu.isDisabledDisplaySublist) return null;
                            return (
                                <li className={getClassName(currentTabName, subMenu.slug, subMenu.searchType)}
                                    key={subMenu.name}>
                                    <Link to={`${routesBase.baseProspect}/${prospectId}/${subMenu.slug}`}>
                                        <PolIcon icon={getIcon(subMenu.searchType)}/>
                                        <span>{t(subMenu.title)}</span>
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            )
        }
        return (
            <li key={tab.name} className={getClassName(currentTabName, tab.slug, tab.searchType)}>
                <Link to={`${routesBase.baseProspect}/${prospectId}/${tab.slug}`}>
                    <PolIcon icon={getIcon(tab.searchType)}/>
                    <span>{tab.title}</span>
                </Link>
            </li>
        )
    });
}

function getIcon(type) {
    switch (type) {
        case 'contact':
            return 'contact';
        case 'task':
            return 'tasks';
        case 'quote':
            return 'quote';
        default:
            return 'business'
    }
}

function getClassName(currentTabName, tabName, type) {
    const activeClassName = currentTabName === tabName ? 'active' : '';
    const typeClassName = type && `tab-${type}`;
    return `${typeClassName ?? tabName} ${activeClassName}`;
}
