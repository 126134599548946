import {useEffect, useState} from "react";
import useFetch from "v4/hooks/useFetch";
import {generateUrl} from "v4/services/api.service";
import {STATISTIC_PROSPECT_LISTING} from "v4/data/apiRoutes";

export default function useProspectsListingStats(checkedIds, isLoading) {
    const [statsForIds] = useState(checkedIds ?? []);

    const [{data}, fetchStats] = useFetch();
    const modules = data?.modules;

    useEffect(() => {
        if (!statsForIds) return;

        fetchStats({url: generateUrl(STATISTIC_PROSPECT_LISTING)});
    }, [fetchStats, statsForIds]);

    return {
        modules,
        areProspectsLoading: isLoading,
        statsForIds,
    }
}
