import React, {forwardRef} from 'react';
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import BlocksStats from "v4/components/ui/BlocksStats/BlocksStats";
import TablesStats from "v4/components/ui/TablesStats/TablesStats";
import ProductsBlocksStats from "v4/components/ui/ProductsBlocksStats/ProductsBlocksStats";

function ProspectStats360Modules({modules, filters, isLoading}, gridRef) {

    if (isLoading) return <InlineLoader/>;

    if (!modules) return null;

    return (
        <div className="stats-360__grid" ref={gridRef}>
            {modules.blocks && <BlocksStats blocks={modules.blocks}/>}
            {modules.tables && <TablesStats tables={modules.tables}/>}
            {modules.products && <ProductsBlocksStats products={modules.products} filters={filters}/>}
        </div>
    )
}

export default forwardRef(ProspectStats360Modules);
