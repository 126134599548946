import React, {useEffect, useState} from "react";
import {generateUrl} from "v4/services/api.service";
import {STATISTIC_CONFIGS} from "v4/data/apiRoutes";
import useAutoFetch from "v4/hooks/useAutoFetch";
import {randomId} from "v4/utils";
import ModuleStatisticsTools
    from "v4/pages/admin/ModuleStatistics/components/ModuleStatisticsTools/ModuleStatisticsTools";

export default function useModuleStatisticsListing(setCardHeaderTools) {
    const [componentKey, setComponentKey] = useState(randomId());
    const {data, isLoading} = useAutoFetch(generateUrl(STATISTIC_CONFIGS), componentKey);
    const statistics = data?.['hydra:member'];

    useEffect(() => {
        if (!statistics) return;

        setCardHeaderTools(<ModuleStatisticsTools
            isProductStatsAlreadyCreated={statistics.metadata.productStatsAlreadyCreated}/>);
    }, [setCardHeaderTools, statistics]);

    return {
        statistics,
        isFirstRenderLoading: isLoading && !data,
        isLoading,
        setComponentKey,
    }
}
