import React from 'react';
import './ProspectStats360Tab.scss';
import {useParams} from "react-router-dom";
import ProspectStats360TabGeneralView
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/components/ProspectStats360TabGeneralView/ProspectStats360TabGeneralView";

export default function ProspectStats360Tab() {
    const {showId} = useParams();

    return <ProspectStats360TabGeneralView isProductView={!!showId}/>
}
