import React from 'react';

export default function BlockStatsValue({summary, devise}) {
    if (!summary) return <span className="block-stats__value">-</span>;

    const {current_value = '-', previous_value = '-'} = summary;
    const formattedCurrentValue = current_value.toLocaleString('fr-FR', {maximumFractionDigits: 2});
    const formattedPreviousValue = previous_value.toLocaleString('fr-FR', {maximumFractionDigits: 2});

    return (
        <span className="block-stats__value">
            {formattedCurrentValue && (
                <span className="block-stats__value__current">
                    {formattedCurrentValue} {devise ?? ''}
                </span>
            )}
            <span className="block-stats__value__previous">
                <span> / </span>
                {formattedPreviousValue && <span>{formattedPreviousValue} {devise ?? ''}</span>}
            </span>
        </span>
    )
}
