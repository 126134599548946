import useAutoFetch from "v4/hooks/useAutoFetch";
import {generateUrl} from "v4/services/api.service";
import {
    STATISTIC_CONFIG_EDIT_FORM,
    STATISTIC_CONFIG_ITEM,
    STATISTIC_CONFIG_NEW,
    STATISTIC_CONFIGS
} from "v4/data/apiRoutes";
import {useHistory} from "react-router-dom";
import useFetch from "v4/hooks/useFetch";
import jsonToFormData from "v4/utils/jsonToFormData";
import {useEffect, useMemo} from "react";
import {routesBase} from "v4/data/appRoutes";
import useNotification from "v4/hooks/useNotification";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

const formId = 'module-statistics-form';
export default function useModuleStatisticsForm(moduleId) {
    const {t} = usePolTranslation();
    const {addSuccessNotification} = useNotification();
    const {push, location: {search}} = useHistory();
    const isEdit = moduleId !== 'new';
    const searchParams = new URLSearchParams(search);
    const {data, isLoading} = useAutoFetch(isEdit
        ? generateUrl(STATISTIC_CONFIG_EDIT_FORM, {moduleId})
        : generateUrl(STATISTIC_CONFIG_NEW, {type: searchParams.get('moduleType')})
    );
    const form = data?.['hydra:member'];
    const formType = useMemo(() => form?.metadata?.moduleType || searchParams.get('moduleType'), [form, searchParams]);

    const [{isError, isFinished, isLoading: isSaving}, saveStatisticConfig] = useFetch();
    const onSubmit = (json) => {
        saveStatisticConfig({
            url: isEdit
                ? generateUrl(STATISTIC_CONFIG_ITEM, {moduleId})
                : generateUrl(STATISTIC_CONFIGS, {type: formType}),
            config: {
                method: isEdit ? 'PUT' : 'POST',
                body: jsonToFormData(json),
                hasToPatchSymfony: true,
            }
        })
    }

    useEffect(() => {
        if (isFinished && !isError) {
            addSuccessNotification(t('success'));
            push(`${routesBase.adminModuleStatistics}`)
        }
    }, [isFinished, isError, push, addSuccessNotification, t]);

    return {
        formType,
        formId,
        form,
        isLoading,
        onSubmit,
        isSaving,
    }
}
