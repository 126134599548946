import useFetch from "v4/hooks/useFetch";
import {useEffect} from "react";

export default function useTableStats(table, statsForIds) {
    const [{data, isLoading}, fetchTable] = useFetch();
    const details = data?.details;

    useEffect(() => {
        fetchTable({
            url: table.links.details.url,
            config: {
                method: statsForIds ? 'POST' : 'GET',
                ...(statsForIds && {body: JSON.stringify({prospectIds: statsForIds})}),
            }
        });
    }, [fetchTable, statsForIds, table.links.details.url]);

    return {
        details,
        isLoading,
    }
}
