import React from 'react';
import ProspectHeadingTabPartial
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/partials/ProspectHeadingTabPartial/ProspectHeadingTabPartial";
import {Link} from "react-router-dom";
import {routesBase} from "v4/data/appRoutes";
import {TAB_STATS_360} from "v4/data/tabNames";
import {PolIcon} from "components/PolIcon/policon";
import ProspectStats360Modules
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/components/ProspectStats360Modules/ProspectStats360Modules";
import {useProspectPageContext} from "v4/contexts/ProspectPageContext";
import useGetProspectStats360Modules
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/hooks/useGetProspectStats360Modules";
import ProspectStats360TabProductViewActions
    from "v4/pages/front/Prospect/components/ProspectTabsSwitcher/components/ProspectStats360Tab/components/ProspectStats360TabProductViewActions/ProspectStats360TabProductViewActions";
import i18n from "i18next";

export default function ProspectStats360TabGeneralView({isProductView}) {
    const {prospectId} = useProspectPageContext();
    const {
        modules,
        hasProducts,
        isLoading,
        gridRef,
        methods,
        filters,
    } = useGetProspectStats360Modules(isProductView);

    return (
        <>
            <ProspectHeadingTabPartial title={isProductView ? "products_view" : "general_view"}
                                       actions={getProspectStats360TabGeneralViewActions({
                                           hasProducts,
                                           isProductView,
                                           prospectId,
                                           modules,
                                           methods,
                                       })}/>
            <ProspectStats360Modules modules={modules} filters={filters} isLoading={isLoading} ref={gridRef}/>
        </>
    )
}

function getProspectStats360TabGeneralViewActions({hasProducts, isProductView, prospectId, methods, modules}) {
    if (isProductView) return () => (
        <ProspectStats360TabProductViewActions methods={methods} productsModules={modules?.products}/>
    )

    if (hasProducts) return () => (
        <Link
            to={`${routesBase.baseProspect}/${prospectId}/${TAB_STATS_360}/product-view`}
            className="prospect-tab__action stats">
            {i18n.t('products_view')}
            <PolIcon icon="return" size={14} className="reverse-h"/>
        </Link>
    )

    return undefined;
}
