import React from 'react';
import FieldWrapper from "v4/components/form/FieldWrapper/FieldWrapper";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";
import {PolIcon} from "components/PolIcon/policon";
import PolButton from "v4/components/ui/PolButton/PolButton";
import {Draggable} from "react-beautiful-dnd";
import {COLLECTION_TYPE} from "v4/data/fieldTypes";

export default function CollectionTypeFieldRow({
                                                   id,
                                                   properties,
                                                   collectionTypeFieldName,
                                                   index,
                                                   fieldsRow,
                                                   removeRow
                                               }) {
    const fieldsName = Object.keys(fieldsRow);

    return (
        <Draggable draggableId={id} index={index}>
            {(provided) => (
                <div className={`collection-type-field__row ${!removeRow ? 'no-remove' : ''}`}
                     ref={provided.innerRef}
                     {...provided.draggableProps}>
                    <div className="drag-handle" {...provided.dragHandleProps}>
                        <PolIcon icon="draggable"/>
                    </div>

                    <PolColumns cols={getColsNumber(properties)} className="input-field-wrapper__row">
                        {fieldsName.map(key => {
                            return (
                                <FieldWrapper key={key}
                                              field={properties[key]}
                                              name={`${collectionTypeFieldName}.${index}.${key}`}/>
                            )
                        })}
                    </PolColumns>

                    {removeRow && (
                        <PolButton className="btn-danger"
                                   onClick={() => removeRow(index)}>
                            <PolIcon icon="trash"/>
                        </PolButton>
                    )}
                </div>
            )}
        </Draggable>
    )
}

function getColsNumber(fields) {
    const cols = Object.entries(fields).length;

    if (cols > 6) return 6;

    return Object.values(fields).some(field => field.type === COLLECTION_TYPE) ? cols - 1 : cols;
}
