import React from 'react';
import FormRow from "v4/components/form/FormRender/components/FormRow/FormRow";
import FormRender from "v4/components/form/FormRender/FormRender";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";

export default function ModuleStatisticsRenderTurnoverForm({formId, form, onSubmit}) {
    return (
        <FormRender form={form}
                    formId={formId}
                    onSubmit={onSubmit}
                    hasToHideRest>
            <FormRow fieldName="statistic_turnover_configuration[label]"/>
            <PolColumns gridTemplateColumns="2fr 2fr 1fr">
                <FormRow fieldName="statistic_turnover_configuration[turnoverConfiguration][groupingField]"/>
                <FormRow fieldName="statistic_turnover_configuration[turnoverConfiguration][quoteStatusTypeCondition]"/>
                <FormRow fieldName="statistic_turnover_configuration[turnoverConfiguration][displayUsingPercent]"/>
            </PolColumns>
        </FormRender>
    )
}
