export const TEXT_TYPE = 'TextType';

export const NUMBER_TYPE = 'NumberType';

export const TEL_TYPE = 'TelType';

export const EMAIL_TYPE = 'EmailType';

export const HIDDEN_TYPE = 'HiddenType';

export const DATE_TIME_TYPE = 'DateTimeType';

export const DATE_TYPE = 'DateType';

export const TEXTAREA_TYPE = 'TextareaType';

export const CHECKBOX_TYPE = 'CheckboxType';

export const CUSTOMER_FILE_TYPE = 'CustomerFileType';

export const QUOTE_LINE_TYPE = 'QuoteLineType';

export const COLLECTION_TYPE = 'CollectionType';

export const CHOICE_TYPE = 'ChoiceType';

export const FILE_TYPE = 'FileType';

export const DISPLAY_FIELDS_TYPE = 'DisplayFieldsType';

export const NUMBER_COMPARE_TYPE = 'NumberCompareType';

export const DATE_COMPARE_TYPE = 'DateCompareType';
