import React, {Component} from 'react'
import {connect} from "react-redux";
import ErrorBoundary from "../../components/Error/errorBoundary";
import {Bar} from 'react-chartjs-2';
import TableTasks from "./_partials/tableTasks";
import TableQuote from "./_partials/tableQuote";
import {PolIcon} from "../../components/PolIcon/policon";
import CountUp from 'react-countup';
import {getApiUrl} from "../../routes";
import UserService from "../../api/user/user";
import {fetchHandler, manageError} from "../../api/datas/datas";
import TableHome from "./_partials/tableHome";
import {formatForView} from "../../utils/viewTypeFormatter";
import {withPolTranslation} from "../../v4/hooks/usePolTranslation";

const illustrationHomepage = require('../../img/illustration-homepage.svg')

class Home extends Component {
    constructor(props) {
        super(props);

        const {t} = this.props;

        let tasksData = [];
        tasksData['daily_tasks'] = [];
        tasksData['daily_tasks'].data = [];
        tasksData['in_progress_tasks'] = [];
        tasksData['in_progress_tasks'].data = [];
        let quotesData = [];
        quotesData['quotes_year'] = [];
        quotesData['signed_quotes'] = [];
        quotesData['signed_quotes'].data = [];
        quotesData['in_progress_quotes'] = [];
        quotesData['in_progress_quotes'].data = [];

        this.state = {
            months: [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"), t("july"), t("august"), t("september"), t("october"), t("november"), t("december")],
            infoTask: {
                name: 'actions',
                id: 'home',
                type: 'datas',
                url: getApiUrl('tasks', 'list'),
                route: 'actions',
                loader: 'table-actions',
                order_type: 'list',
                order_entity: 'Task',
                tabs: ['daily_tasks', 'in_progress_tasks'],
                classNameTabs: 'homeTask',
                tabActive: 'daily_tasks',
                number: {start: 0, end: 0, count: 0},
            },
            infoQuote: {
                name: 'devis',
                id: 'home',
                type: 'datas',
                url: getApiUrl('devis', 'list'),
                route: 'devis_offres',
                loader: 'table-devis',
                order_type: 'list',
                order_entity: 'Quote',
                tabs : ['in_progress_quotes', 'signed_quotes'],
                classNameTabs: 'homeQuote',
                tabActive: 'in_progress_quotes',
                number: {start: 0, end: 0, count: 0},
            },
            tasksData: tasksData,
            quotesData: quotesData,
        }
    }

    componentDidMount() {
        const {manageError} = this.props;
        const user = UserService.getUser();
        let token = user.token ? user.token : localStorage.getItem('token');

        fetch(process.env.REACT_APP_HOST_API + getApiUrl('home', 'getData').replace('{stats}', 'task'), {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            }
        }).then(
            fetchHandler,
            error => {
                manageError(true, error);
            }
        ).then(
            data => {
                if(data) {
                    this.setState({tasksData: data});
                }
            },
            error => {
                manageError(true, error);
            }
        )

        fetch(process.env.REACT_APP_HOST_API + getApiUrl('home', 'getData').replace('{stats}', 'quote'), {
            method: 'GET',
            headers: {
                Accept: 'application/ld+json',
                'Content-Type': 'application/json',
                "X-POL-AUTH": 'Bearer ' + token
            }
        }).then(
            fetchHandler,
            error => {
                manageError(true, error);
            }
        ).then(
            data => {
                if(data) {
                    this.setState({quotesData: data});
                }
            },
            error => {
                manageError(true, error);
            }
        )
    }

    render() {
        const {infoTask, infoQuote, tasksData, quotesData, months} = this.state;
        const {t} = this.props;

        return (
            <div className={'homepage page page-list main-content__body__slide'}>
                <div className={'page-list__content'}>
                    <ErrorBoundary>
                        <div className={'row'}>
                            <div className={'col-12 col-md-6 col-lg-3 stat'}>
                                <div className={'stat__content'}>
                                    <CountUp className={'stat__number'}
                                             start={0}
                                             end={(tasksData && tasksData['daily_tasks']) ?
                                                 tasksData['daily_tasks'].data.length
                                                : 0}
                                             duration={3}/>
                                    <div className={'stat__name'}>{t("tasks_of_current_day")}</div>
                                    <PolIcon icon={'tasks'} className={'stat__icon'}/>
                                </div>
                            </div>
                            <div className={'col-12 col-md-6 col-lg-3 stat'}>
                                <div className={'stat__content'}>
                                    <CountUp className={'stat__number'}
                                         start={0}
                                         end={(tasksData && tasksData['in_progress_tasks']) ?
                                             tasksData['in_progress_tasks'].data.length
                                            : 0}
                                         duration={3}/>
                                    <div className={'stat__name'}>{t("current_tasks")}</div>
                                    <PolIcon icon={'tasks'} className={'stat__icon'}/>
                                </div>
                            </div>
                            <div className={'col-12 col-md-6 col-lg-3 stat'}>
                                <div className={'stat__content'}>
                                    <CountUp className={'stat__number'}
                                         start={0}
                                         end={(quotesData && 'sales_revenue_in_progress' in quotesData) ? quotesData['sales_revenue_in_progress'] : 0}
                                         duration={3}
                                         formattingFn={value => formatForView(value, 'stat-money')}
                                         suffix=" €"/>

                                    <div className={'stat__name'}>{t("current_quotes")} ({(quotesData && quotesData['in_progress_quotes']) ?
                                        quotesData['in_progress_quotes'].data.length : 0})</div>
                                    <PolIcon icon={'file-invoice-dollar'} className={'stat__icon'}/>
                                </div>
                            </div>
                            <div className={'col-12 col-md-6 col-lg-3 stat'}>
                                <div className={'stat__content'}>
                                    <CountUp className={'stat__number'}
                                         start={0}
                                         end={(quotesData && 'sales_revenue_won' in quotesData) ? quotesData['sales_revenue_won'] : 0}
                                         duration={3}
                                         formattingFn={value => formatForView(value, 'stat-money')}
                                         suffix=" €"/>

                                    <div className={'stat__name'}>{t("signed_quotes_current_month")} ({(quotesData && quotesData['signed_quotes']) ?
                                        quotesData['signed_quotes'].data.length : 0})</div>
                                    <PolIcon icon={'file-contract'} className={'stat__icon'}/>
                                </div>
                            </div>
                        </div>
                        <div className={'homepage__datatable-container row'}>
                            <div className={'col-md-6 col-sm-12'}>
                                <ErrorBoundary>
                                    <TableHome info={infoTask} datas={tasksData} />
                                </ErrorBoundary>
                            </div>
                            <div className={'col-md-6 col-sm-12'}>
                                <ErrorBoundary>
                                    <TableHome info={infoQuote} datas={quotesData} />
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div className={'row graph'}>
                            <div className={'col-12 col-lg-6'}>
                                <img src={illustrationHomepage} alt={'POL CRM'}/>
                            </div>
                            <div className={'col-12 col-lg-6'}>
                                <p className={'graph__title'}>{t('the_sales')}</p>
                                <div>
                                    {(quotesData && quotesData['signed_quotes'] && quotesData['in_progress_quotes'] && quotesData['in_progress_quotes_ponderate']) ?
                                        <ErrorBoundary>
                                            <Bar
                                                data={{
                                                    labels: months,
                                                    datasets: [
                                                        {
                                                            label: t('signed_sales'),
                                                            backgroundColor: 'rgb(55, 46, 116)',
                                                            borderColor: 'rgb(55, 46, 116)',
                                                            data: quotesData['signed_quotes']['info_graph'],
                                                        },
                                                        {
                                                            label: t('sales_previsions'),
                                                            backgroundColor: 'rgb(200, 190, 254)',
                                                            borderColor: 'rgb(200, 190, 254)',
                                                            data: quotesData['in_progress_quotes']['info_graph'],
                                                        },
                                                        {
                                                            label: t('weighted_sales_previsions'),
                                                            backgroundColor: 'rgb(237, 116, 147)',
                                                            borderColor: 'rgb(237, 116, 147)',
                                                            data: quotesData['in_progress_quotes_ponderate']['info_graph'],
                                                        }
                                                    ]
                                                }}
                                                height={400}
                                                width={700}
                                                options={{
                                                    maintainAspectRatio: false,
                                                    legend: {
                                                        position: 'bottom',
                                                    },
                                                    scales: {
                                                        yAxes: [{
                                                            ticks: {
                                                                beginAtZero: true,
                                                                callback: function (label) {
                                                                    return parseFloat(label).toLocaleString(
                                                                        undefined,
                                                                        {minimumFractionDigits: 2}
                                                                    ) + ' €';
                                                                }
                                                            }
                                                        }]
                                                    },
                                                    tooltips: {
                                                        callbacks: {
                                                            label: function (tooltipItem) {
                                                                return parseFloat(tooltipItem.yLabel).toLocaleString(
                                                                    undefined,
                                                                    {minimumFractionDigits: 2}
                                                                ) + ' €';
                                                            }
                                                        }
                                                    },
                                                }}
                                            />
                                        </ErrorBoundary>
                                    : null}

                                </div>
                            </div>
                        </div>

                        <div className={'row table-stat'}>
                            <div className={'col-12'}>
                                <ErrorBoundary>
                                    <TableTasks tableTasks={tasksData['table_tasks']} months={months} />
                                </ErrorBoundary>
                            </div>
                        </div>
                        <div className={'row table-stat'}>
                            <div className={'col-12'}>
                                <ErrorBoundary>
                                    <TableQuote quotesYear={quotesData['quotes_year']}
                                                quotesStatus={quotesData['quote_states']}
                                                tableQuotes={quotesData['table_quotes']}
                                                months={months} />
                                </ErrorBoundary>
                            </div>
                        </div>
                    </ErrorBoundary>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        manageError: (code, error, errorMessage) => dispatch(manageError(code, error, errorMessage)),
    }
};

export default connect(
    null,
    mapDispatchToProps
)(withPolTranslation(React.memo(Home)));
