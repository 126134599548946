import {COLLECTION_TYPE, DATE_TIME_TYPE, DATE_TYPE, EMAIL_TYPE} from "v4/data/fieldTypes";
import {getFormattedValue} from "v4/utils/index";
import moment from "moment";

export default function getFieldConstraints(constraints, type, values, t) {
    const inputConstraints = {};
    let realType = type;
    
    if (!constraints) return inputConstraints;

    if (constraints.required) {
        inputConstraints.required = t('validation_required')
    }

    if (constraints.Length) {
        if (constraints.Length?.max !== undefined) {
            inputConstraints.maxLength = {
                value: constraints.Length.max,
                message: t('validation_maxLength', {max: constraints.Length.max}),
            };
        }
        if (constraints.Length?.min !== undefined) {
            inputConstraints.minLength = {
                value: constraints.Length.min,
                message: t('validation_minLength', {min: constraints.Length.min}),
            };
        }
    }

    if (constraints.Date) {
        realType = DATE_TYPE;
    }

    if (constraints.DateTime) {
        realType = DATE_TIME_TYPE;
    }

    if (constraints.Email) {
        realType = EMAIL_TYPE;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            email: (fieldValue) => {
                if (fieldValue === '') {
                    return true;
                }
                return /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/.test(fieldValue) || t('validation_email');
            }
        }
    }

    if (constraints.NotEqualTo) {
        inputConstraints.validate = {
            ...inputConstraints.validate,
            notEqualTo: (fieldValue) => {
                if (!inputConstraints.required && fieldValue === '') {
                    return true;
                }
                return fieldValue.match(new RegExp(`^${constraints.NotEqualTo?.value}$`, 'g')) === null
                    || t('validation_notEqualTo', {pattern: constraints.NotEqualTo?.value});
            }
        }
    }

    if (constraints.GreaterThan) {
        const constraintValue = constraints.GreaterThan?.propertyPath
            ? values[constraints.GreaterThan?.propertyPath]
            : getFormattedValue(realType, constraints.GreaterThan?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            greaterThan: (fieldValue) => {
                if (!inputConstraints.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(realType)) {
                    return moment(fieldValue) > moment(constraintValue) || t('validation_greaterThan', {number: getFormattedValue(realType, constraintValue, {keepTime: true})});
                }
                return fieldValue > constraintValue || t('validation_greaterThan', {number: constraints.GreaterThan?.value})
            },
        }
    }

    if (constraints.GreaterThanOrEqual) {
        const constraintValue = constraints.GreaterThanOrEqual.propertyPath
            ? values[constraints.GreaterThanOrEqual.propertyPath]
            : getFormattedValue(realType, constraints.GreaterThanOrEqual?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            GreaterThanOrEqual: (fieldValue) => {
                if (!inputConstraints.required && fieldValue === '') {
                    return true;
                }

                if ([DATE_TYPE, DATE_TIME_TYPE].includes(realType)) {
                    return moment(fieldValue) >= moment(constraintValue) || t('validation_greaterThanOrEqual', {number: getFormattedValue(realType, constraintValue, {keepTime: true})});
                }
                return fieldValue >= constraintValue || t('validation_greaterThanOrEqual', {number: constraints.GreaterThanOrEqual?.value})
            },
        }
    }

    if (constraints.LessThan) {
        const constraintValue = constraints.LessThan?.propertyPath
            ? values[constraints.LessThan?.propertyPath]
            : getFormattedValue(realType, constraints.LessThan?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            lessThan: (fieldValue) => {
                if (!inputConstraints.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(realType)) {
                    return moment(fieldValue) < moment(constraintValue) || t('validation_lessThan', {number: getFormattedValue(realType, constraintValue, {keepTime: true})});
                }
                return fieldValue < constraintValue || t('validation_lessThan', {number: constraintValue})
            },
        }
    }

    if (constraints.LessThanOrEqual) {
        const constraintValue = constraints.LessThanOrEqual?.propertyPath
            ? values[constraints.LessThanOrEqual?.propertyPath]
            : getFormattedValue(realType, constraints.LessThanOrEqual?.value, {toEndOfDay: true})
        ;
        inputConstraints.validate = {
            ...inputConstraints.validate,
            lessThanOrEqual: (fieldValue) => {
                if (!inputConstraints.required && fieldValue === '') {
                    return true;
                }
                if ([DATE_TYPE, DATE_TIME_TYPE].includes(realType)) {
                    return moment(fieldValue) < moment(constraintValue) || t('validation_lessThanOrEqual', {number: getFormattedValue(realType, constraintValue, {keepTime: true})});
                }
                return fieldValue <= constraintValue || t('validation_lessThanOrEqual', {number: constraintValue})
            },
        }
    }

    if (constraints.Positive) {
        if (constraints.GreaterThan?.value < 0 || constraints.GreaterThanOrEqual?.value < 0) {
            inputConstraints.min = {
                value: 0,
                message: t('validation_greaterThanOrEqual', {number: 0}),
            };
        }
    }

    if (constraints.File) {
        inputConstraints.validate = {
            ...inputConstraints.validate,
            file: (fieldValue) => {
                if (!inputConstraints.required && (fieldValue === '' || fieldValue?.file === '')) {
                    return true;
                }
                return fieldValue.file !== '' || t('validation_file');
            },
        }
    }

    if (constraints.Regex) {
        inputConstraints.pattern = {
            value: new RegExp(constraints.Regex?.pattern),
            message: t('validation_regex'),
        };
    }

    if (constraints.NotBlank || constraints.NotNull) {
        inputConstraints.required = t('validation_required');
    }
    
    if (constraints.Range) {
        if (constraints.Range?.max !== undefined) {
            inputConstraints.max = {
                value: Number(constraints.Range.max),
                message: t('validation_max', {max: constraints.Range.max}),
            };
        }
        if (constraints.Range?.min !== undefined) {
            inputConstraints.min = {
                value: Number(constraints.Range.min),
                message: t('validation_min', {min: constraints.Range.min}),
            };
        }
    }

    if (realType === COLLECTION_TYPE) {
        inputConstraints.collectionType = {
            allowAdd: constraints.allow_add,
            allowDelete: constraints.allow_delete,
        };
    }

    return inputConstraints;
}
