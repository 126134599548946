import React, {useRef} from 'react';
import BlockStats from "v4/components/ui/BlockStats/BlockStats";
import './BlocksStats.scss';

export default function BlocksStats({blocks, statsForIds, shouldShowGraphInPopup = false}) {
    const graphsSlotRef = useRef(null);

    return (
        <div className="blocks-stats">
            <div className="blocks-stats__grid">
                {blocks.map((block, index) => (
                    <BlockStats key={block.id ?? index}
                                block={block}
                                statsForIds={statsForIds}
                                graphsSlotRef={shouldShowGraphInPopup ? undefined : graphsSlotRef}
                                {...(!shouldShowGraphInPopup && index === 0 && {shouldOpenByDefault: true})}
                    />
                ))}
            </div>
            {!shouldShowGraphInPopup && <div className="blocks-stats__graphs-slot" ref={graphsSlotRef}/>}
        </div>
    )
}
