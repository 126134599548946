import React, {useMemo} from 'react';
import {Controller, useFormContext, useWatch} from "react-hook-form";
import AutocompleteSelect from "v4/components/form/Field/components/AutocompleteSelect/AutocompleteSelect";
import CustomReactSelect from "v4/components/form/Field/components/CustomReactSelect/CustomReactSelect";
import DisplayFields from "v4/components/form/Field/components/DisplayFields/DisplayFields";
import InputCheckboxes from "v4/components/form/Field/components/InputCheckboxes/InputCheckboxes";
import InputCompare from "v4/components/form/Field/components/InputCompare/InputCompare";
import InputDate from "v4/components/form/Field/components/InputDatetime/InputDate";
import InputEmail from "v4/components/form/Field/components/InputEmail/InputEmail";
import InputFile from "v4/components/form/Field/components/InputFile/InputFile";
import InputNumber from "v4/components/form/Field/components/InputNumber/InputNumber";
import InputPostalCode from "v4/components/form/Field/components/InputPostalCode/InputPostalCode";
import InputRadio from "v4/components/form/Field/components/InputRadio/InputRadio";
import InputRange from "v4/components/form/Field/components/InputRange/InputRange";
import InputRichText from "v4/components/form/Field/components/InputRichText/InputRichText";
import InputSimpleCheckbox from "v4/components/form/Field/components/InputSimpleCheckbox/InputSimpleCheckbox";
import InputTel from "v4/components/form/Field/components/InputTel/InputTel";
import InputText from "v4/components/form/Field/components/InputText/InputText";
import QuoteLines from "v4/components/form/Field/components/QuoteLines/QuotesLines";
import 'v4/components/form/Field/Field.scss';
import useConditionalField from "v4/components/form/Field/hooks/useConditionalField";
import useDependsOnField from "v4/components/form/Field/hooks/useDependsOnField";
import {usePolTranslation} from "v4/hooks/usePolTranslation";
import InputSiret from "v4/components/form/Field/components/InputSiret/InputSiret";
import {
    CHECKBOX_TYPE,
    CHOICE_TYPE,
    COLLECTION_TYPE,
    CUSTOMER_FILE_TYPE,
    DATE_COMPARE_TYPE,
    DATE_TIME_TYPE,
    DATE_TYPE,
    EMAIL_TYPE,
    FILE_TYPE,
    HIDDEN_TYPE,
    NUMBER_COMPARE_TYPE,
    NUMBER_TYPE,
    QUOTE_LINE_TYPE,
    TEL_TYPE,
    TEXT_TYPE,
    TEXTAREA_TYPE
} from "v4/data/fieldTypes";
import CollectionTypeField from "v4/components/form/Field/components/CollectionTypeField/CollectionTypeField";
import getFieldConstraints from "v4/utils/getFieldConstraints";
import clearCollectionTypeValue from "v4/utils/clearCollectionTypeValue";

export default React.forwardRef(function Field({
                                                   type,
                                                   name,
                                                   label,
                                                   value: defaultValue,
                                                   choices,
                                                   multiple,
                                                   expanded,
                                                   properties,
                                                   constraints,
                                                   attr,
                                                   placeholder = '',
                                                   onFieldChange,
                                                   specificParams = {},
                                                   prefix = '',
                                                   entry_type,
                                                   entityId,
                                                   impossibleValues = [],
                                               }, ref) {
    const {t} = usePolTranslation();
    const {register, control, getFieldState, formState} = useFormContext();
    const {error} = getFieldState(name, formState);

    // Fix readOnly attribute
    if ('readonly' in (attr ?? {})) {
        attr.readOnly = attr.readonly;
        delete attr.readonly;
    }

    const {
        'data-condition-field': conditionField,
        'data-condition-value': conditionFieldValues,
        'data-postal-code-target': postalCodeTarget,
        'data-wysiwyg-mode': richTextEditorMode,
        'display-fields': displayFields,
        dependsOnField,
        type: attrType,
        ...cleanedAttr
    } = attr ?? {};
    const hideField = useConditionalField(conditionField, conditionFieldValues);
    const {
        dependsOnFieldChoices,
        autocompleteAdditionnalSearchFilter
    } = useDependsOnField(name, dependsOnField, choices);

    const constraintsName = Object.values(constraints ?? {}).map(({propertyPath}) => propertyPath).filter(Boolean);
    const values = useWatch({control, disabled: (!constraintsName || constraintsName.length === 0)}) ?? [];
    const inputConstraints = useMemo(() => getFieldConstraints(constraints, type, values, t), [constraints, type, values, t]);

    if (hideField) return null;
    if (attrType === 'hidden') {
        type = 'hidden';
    }

    switch (type) {
        case 'text':
        case TEXT_TYPE:
            if (postalCodeTarget) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputPostalCode fieldName={fieldName}
                                                            fieldValue={fieldValue}
                                                            inputConstraints={inputConstraints}
                                                            placeholder={placeholder}
                                                            prefix={prefix}
                                                            attr={cleanedAttr}
                                                            error={error}
                                                            postalCodeTarget={postalCodeTarget}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                                onFieldChange && onFieldChange(value, {name: fieldName})
                                                            }}/>
                                }}/>
                </>
            }

            if (attrType === 'siret') {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputSiret fieldName={fieldName}
                                                       fieldValue={fieldValue}
                                                       inputConstraints={inputConstraints}
                                                       placeholder={placeholder}
                                                       prefix={prefix}
                                                       attr={cleanedAttr}
                                                       error={error}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value, {name: fieldName})
                                                       }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}
                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputText fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value, {name: fieldName})
                                                  }}/>
                            }}/>
            </>
        case 'number':
        case NUMBER_TYPE:
            if (attrType === 'range' || attrType === 'slider') {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputRange fieldValue={fieldValue}
                                                       fieldName={fieldName}
                                                       attributes={cleanedAttr}
                                                       htmlConstraints={inputConstraints}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value, {name: fieldName})
                                                       }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputNumber fieldName={fieldName}
                                                    fieldValue={fieldValue}
                                                    attr={cleanedAttr}
                                                    inputConstraints={inputConstraints}
                                                    placeholder={placeholder}
                                                    prefix={prefix}
                                                    error={error}
                                                    onChange={(value) => {
                                                        onChange(value)
                                                        onFieldChange && onFieldChange(value, {name: fieldName})
                                                    }}/>
                            }}/>
            </>
        case TEL_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputTel fieldName={fieldName}
                                                 inputConstraints={inputConstraints}
                                                 placeholder={placeholder}
                                                 prefix={prefix}
                                                 attr={cleanedAttr}
                                                 error={error}
                                                 fieldValue={fieldValue}
                                                 onChange={(value) => {
                                                     onChange(value)
                                                     onFieldChange && onFieldChange(value, {name: fieldName})
                                                 }}/>
                            }}/>
            </>
        case 'email':
        case EMAIL_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputEmail fieldName={fieldName}
                                                   fieldValue={fieldValue}
                                                   inputConstraints={inputConstraints}
                                                   placeholder={placeholder}
                                                   prefix={prefix}
                                                   attr={cleanedAttr}
                                                   error={error}
                                                   onChange={(value) => {
                                                       onChange(value)
                                                       onFieldChange && onFieldChange(value, {name: fieldName})
                                                   }}/>
                            }}/>
            </>
        case 'hidden':
        case HIDDEN_TYPE:
            return <>
                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {name: fieldName, value: fieldValue}}) => {
                                return <input className={`input-field${error ? ' has-error' : ''}`}
                                              type="hidden"
                                              id={prefix + fieldName}
                                              defaultValue={fieldValue}
                                              {...cleanedAttr}/>
                            }}/>
            </>
        case 'datetime':
        case DATE_TIME_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputDate fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  withTime={true}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value, {name: fieldName})
                                                  }}/>
                            }}/>
            </>
        case 'date':
        case DATE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputDate fieldName={fieldName}
                                                  fieldValue={fieldValue}
                                                  inputConstraints={inputConstraints}
                                                  placeholder={placeholder}
                                                  prefix={prefix}
                                                  attr={cleanedAttr}
                                                  error={error}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value, {name: fieldName})
                                                  }}/>
                            }}/>
            </>
        case 'textarea':
        case TEXTAREA_TYPE:
            if (cleanedAttr?.['rich-text-editor']) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputRichText fieldValue={fieldValue}
                                                          fieldName={fieldName}
                                                          inputConstraints={inputConstraints}
                                                          isRestricted={richTextEditorMode === 'restricted'}
                                                          {...cleanedAttr}
                                                          ref={ref}
                                                          overrideTinyMceProps={specificParams}
                                                          onChange={(value) => {
                                                              onChange(value)
                                                              onFieldChange && onFieldChange(value, {name: fieldName})
                                                          }}/>
                                }}/>
                </>
            }

            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <textarea className={`input-field${error ? ' has-error' : ''}`}
                                                 id={prefix + fieldName} value={fieldValue} placeholder={placeholder}
                                                 {...cleanedAttr} data-is-required={!!inputConstraints?.required}
                                                 onChange={(e) => {
                                                     onChange(e)
                                                     onFieldChange && onFieldChange(e.currentTarget.value)
                                                 }}/>
                            }}/>
            </>
        case 'checkbox':
        case CHECKBOX_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? false}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputSimpleCheckbox fieldValue={fieldValue}
                                                            fieldName={fieldName}
                                                            inputConstraints={inputConstraints}
                                                            prefix={prefix}
                                                            attr={cleanedAttr}
                                                            error={error}
                                                            onChange={(booleanValue) => {
                                                                onChange(booleanValue)
                                                                onFieldChange && onFieldChange(booleanValue)
                                                            }}/>
                            }}/>
            </>
        case CUSTOMER_FILE_TYPE:
        case FILE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                return <InputFile fieldValue={fieldValue}
                                                  fieldName={fieldName} multiple={false}
                                                  properties={properties} register={register}
                                                  htmlConstraints={inputConstraints}
                                                  ref={ref}
                                                  onChange={(value) => {
                                                      onChange(value)
                                                      onFieldChange && onFieldChange(value, {name: fieldName})
                                                  }}/>
                            }}/>
            </>
        case COLLECTION_TYPE:
            if (entry_type === CUSTOMER_FILE_TYPE) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints}
                                render={({field: {onChange, name: fieldName, value: fieldValue}}) => {
                                    return <InputFile fieldValue={fieldValue} ref={ref}
                                                      fieldName={fieldName} multiple={true}
                                                      properties={properties} register={register}
                                                      htmlConstraints={inputConstraints}
                                                      onChange={(value) => {
                                                          onChange(value)
                                                          onFieldChange && onFieldChange(value, {name: fieldName})
                                                      }}/>
                                }}/>
                </>
            }

            if (entry_type === QUOTE_LINE_TYPE) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? []}
                                render={({field: {onChange}}) => {
                                    return <QuoteLines properties={properties}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value, {name})
                                                       }}/>
                                }}/>
                </>
            }

            return (
                <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control}
                                name={name}
                                rules={inputConstraints}
                                defaultValue={clearCollectionTypeValue(defaultValue, properties) ?? []}
                                render={({field: {value}, fieldState: {error}}) => {
                                    return <CollectionTypeField label={label}
                                                                name={name}
                                                                value={value}
                                                                properties={properties}
                                                                hasError={!!error}
                                                                collectionTypeConstraints={inputConstraints.collectionType}/>
                                }}/>
                </>

            )

        case 'select':
        case CHOICE_TYPE:
            if (displayFields) {
                return <DisplayFields label={label} choices={choices} attr={cleanedAttr}/>
            }

            const {'is-expanded': isExpanded} = cleanedAttr ?? {};
            if ((isExpanded || expanded) && !multiple) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? ""}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <InputRadio fieldName={fieldName} fieldValue={fieldValue}
                                                       choices={choices} prefix={prefix}
                                                       htmlConstraints={inputConstraints}
                                                       onChange={(value) => {
                                                           onChange(value)
                                                           onFieldChange && onFieldChange(value, {name: fieldName})
                                                       }}/>
                                }}/>
                </>
            }
            if ((isExpanded || expanded) && multiple) {
                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints} defaultValue={defaultValue ?? []}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <InputCheckboxes fieldName={fieldName} fieldValue={fieldValue}
                                                            choices={choices} prefix={prefix}
                                                            htmlConstraints={inputConstraints}
                                                            onChange={(value) => {
                                                                onChange(value)
                                                                onFieldChange && onFieldChange(value, {name: fieldName})
                                                            }}/>
                                }}/>
                </>
            }

            if (attrType === 'autocomplete') {
                const {'autocomplete_entity': entity} = cleanedAttr;

                return <>
                    {error && <p className="input-field__errors">{error.message}</p>}

                    <Controller control={control} name={name} rules={inputConstraints}
                                defaultValue={defaultValue ?? (multiple ? [] : "")}
                                render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                    return <AutocompleteSelect name={fieldName}
                                                               value={fieldValue}
                                                               options={dependsOnFieldChoices}
                                                               entity={entity}
                                                               autocompleteAdditionnalSearchFilter={autocompleteAdditionnalSearchFilter}
                                                               isMulti={multiple}
                                                               selectParams={specificParams}
                                                               placeholder={placeholder}
                                                               htmlConstraints={inputConstraints}
                                                               impossibleValues={impossibleValues}
                                                               onChange={(value) => {
                                                                   onChange(value)
                                                                   onFieldChange && onFieldChange(value, {name: fieldName})
                                                               }}/>
                                }}/>
                </>
            }
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? (multiple ? [] : "")}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <CustomReactSelect name={fieldName} value={fieldValue}
                                                          options={dependsOnFieldChoices} isMulti={multiple}
                                                          selectParams={specificParams} placeholder={placeholder}
                                                          htmlConstraints={inputConstraints}
                                                          onChange={(value) => {
                                                              onChange(value)
                                                              onFieldChange && onFieldChange(value, {name: fieldName})
                                                          }}/>
                            }}/>
            </>
        case NUMBER_COMPARE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? {equal: '=', begin: "", end: ""}}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <InputCompare type="number" fieldValue={fieldValue}
                                                     htmlConstraints={inputConstraints}
                                                     onChange={(value) => {
                                                         onChange(value)
                                                         onFieldChange && onFieldChange(value, {name: fieldName})
                                                     }}/>
                            }}/>
            </>
        case DATE_COMPARE_TYPE:
            return <>
                {error && <p className="input-field__errors">{error.message}</p>}

                <Controller control={control} name={name} rules={inputConstraints}
                            defaultValue={defaultValue ?? {equal: '=', begin: "", end: ""}}
                            render={({field: {name: fieldName, value: fieldValue, onChange}}) => {
                                return <InputCompare type="date" fieldValue={fieldValue}
                                                     htmlConstraints={inputConstraints}
                                                     onChange={(value) => {
                                                         onChange(value)
                                                         onFieldChange && onFieldChange(value, {name: fieldName})
                                                     }}/>
                            }}/>
            </>
        default:
            return <p className="input-field__error">L'input de type {type ?? '"erreur"'} n'est pas géré</p>;
    }
})
