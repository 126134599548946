import React from 'react';
import FormRow from "v4/components/form/FormRender/components/FormRow/FormRow";
import FormRender from "v4/components/form/FormRender/FormRender";
import PolColumns from "v4/components/utils/PolColumns/PolColumns";

export default function ModuleStatisticsRenderTableForm({formId, form, onSubmit}) {
    return (
        <FormRender form={form}
                    formId={formId}
                    onSubmit={onSubmit}
                    hasToHideRest>
            <FormRow fieldName="statistic_table_configuration[label]"/>
            <FormRow fieldName="statistic_table_configuration[tableConfiguration][maxRowCount]"/>
            <FormRow fieldName="statistic_table_configuration[tableConfiguration][tableType]"/>
            <FormRow fieldName="statistic_table_configuration[tableConfiguration][extraFields]"/>
            <PolColumns cols={2}>
                <FormRow fieldName="statistic_table_configuration[displayListing]"/>
                <FormRow fieldName="statistic_table_configuration[displaySheet]"/>
            </PolColumns>
        </FormRender>
    )
}
