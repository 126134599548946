import React from 'react';
import ProductsBlockStats from "v4/components/ui/ProductsBlockStats/ProductsBlockStats";

export default function ProductsBlocksStats({products, statsForIds, filters}) {
    return (
        products.map(productsBlock => (
            <ProductsBlockStats key={productsBlock.id}
                                productsBlock={productsBlock}
                                statsForIds={statsForIds}
                                filters={filters}/>
        ))
    )
}
