import React from 'react';
import './PieStats.scss';
import {Pie} from "react-chartjs-2";
import usePieStats from "v4/components/ui/PieStats/hooks/usePieStats";
import InlineLoader from "v4/components/ui/InlineLoader/InlineLoader";
import ErrorBoundary from "components/Error/errorBoundary";

export default function PieStats({pie, statsForIds}) {
    const {isLoading, graphData} = usePieStats(pie, statsForIds);

    return (
        <ErrorBoundary>
            <div className="pie-stats">
                <h3 className="pie-stats__title">{pie.label}</h3>
                <div className="pie-stats__graph">
                    {isLoading && <InlineLoader/>}

                    {graphData && (
                        <Pie data={{...graphData.data}}
                             options={{...graphData.options}}/>
                    )}
                </div>
            </div>
        </ErrorBoundary>
    )
}
